/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useMemo, useState} from 'react'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {useIntl} from 'react-intl'
import {create} from 'zustand'
import styled from 'styled-components'

import {Cult, useCultsStore} from '@pages/CultsPage/store'

import {AvatarModel} from '@api/models'

import {AddEditCultDrawerContent} from './AddEditCultDrawerContent'

import {KTIcon} from '../../../helpers'

const MySwal = withReactContent(Swal)

type AddEditCultDrawerStore = {
  opened: boolean
  cultId?: number
  open: (cultId?: number) => void
  close: () => void
}

export const useAddEditCultDrawerStore = create<AddEditCultDrawerStore>((set) => ({
  opened: false,
  cultId: undefined,
  open: (cultId) => set((state) => ({...state, opened: true, cultId})),
  close: () => set((state) => ({...state, opened: false, cultId: undefined})),
}))

const AddEditCultDrawer = () => {
  const intl = useIntl()
  const {cults, createCult, updateCult} = useCultsStore()
  const {opened, close, cultId} = useAddEditCultDrawerStore()

  const cult = useMemo(() => cults?.find(({id}) => id === cultId), [cultId, cults])
  // console.log('### AddEditCultDrawer.cult', cult)

  const [newCultValues, setNewCultValues] = useState<Cult | null>(null)
  const [avatar, setAvatar] = useState<AvatarModel | null>(null)

  const valid = newCultValues?.name?.trim() && avatar

  useEffect(() => {
    if (opened) {
      if (cult) {
        setAvatar(cult.file ?? null)
        setNewCultValues(cult)
      }
    } else {
      setAvatar(null)
      setNewCultValues(null)
    }
  }, [opened, cult])

  if (!opened) return null

  const handleValuesChange = (data) => {
    setNewCultValues(data)
  }
  const handleSubmitClick = () => {
    if (!newCultValues) return

    close()

    if (cult) {
      updateCult(cult.id!, {...newCultValues, file_id: avatar?.id})
    } else {
      createCult({...newCultValues, file_id: avatar?.id})
    }

    MySwal.fire({
      position: 'center',
      icon: 'success',
      title: intl.formatMessage({id: cult ? 'MSG.CULT.UPDATED' : 'MSG.CULT.ADDED'}),
      showConfirmButton: false,
      timer: 1500,
      heightAuto: false,
    })
    setNewCultValues(null)
  }

  return (
    <>
      {opened ? <Overlay onClick={close} /> : null}
      <Wrapper className='bg-body' $hidden={!opened}>
        {/* begin::Card */}
        <div className='card shadow-none rounded-0 w-100'>
          {/* begin::Header */}
          <div className='card-header' id='kt_help_header'>
            <h5 className='card-title fw-bold'>
              {intl.formatMessage({id: cult ? 'EDIT.CULT' : 'ADD.CULT'})}
            </h5>

            <div className='card-toolbar'>
              <button
                type='button'
                className='btn btn-sm btn-icon explore-btn-dismiss me-n5'
                onClick={close}
              >
                <KTIcon iconName='cross' className='fs-2' />
              </button>
            </div>
          </div>
          {/* end::Header */}

          {/* begin::Body */}
          <AddEditCultDrawerContent
            initialValues={cult}
            onAvatarChange={setAvatar}
            onValuesChange={handleValuesChange}
          />
          {/* end::Body */}
          {/* begin::Footer */}
          <div className='card-footer py-5 text-center' id='kt_activities_footer'>
            <div className='d-flex justify-content-end'>
              <button
                id='kt_addsim_close'
                className='btn btn-secondary d-flex justify-content-end me-3'
                onClick={close}
              >
                {intl.formatMessage({id: 'TREE.ADD.CLOSE'})}
              </button>
              <button
                className='btn btn-success d-flex justify-content-end'
                disabled={!valid}
                onClick={handleSubmitClick}
              >
                {intl.formatMessage({id: 'TREE.ADD.SAVE'})}
              </button>
            </div>
          </div>
          {/* end::Footer */}
        </div>
        {/* end::Card */}
      </Wrapper>
    </>
  )
}

export {AddEditCultDrawer}

const Wrapper = styled.div<{$hidden: boolean}>`
  display: ${({$hidden}) => ($hidden ? 'none' : 'flex')};

  position: fixed;
  top: 0;
  right: 0;

  width: 525px;
  height: 100%;

  z-index: 106;

  @media (max-width: 800px) {
    width: 350px;
  }
`

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.25);

  z-index: 105;
`
