import {Relation, useRelationsStore} from '@pages/RelationsPage/store'
import {useMemo} from 'react'

const useRelationsMap = (): {[key: Relation['status']]: Relation} | undefined => {
  const {relations} = useRelationsStore()

  return useMemo(() => {
    return relations?.reduce((res, relation) => {
      res[relation.status] = relation

      return res
    }, {})
  }, [relations])
}

export default useRelationsMap
