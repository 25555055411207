import axios from 'axios'
import {Relation} from '@pages/RelationsPage/store'

const API_URL = process.env.REACT_APP_API_URL

const PARTNERSHIP_BASE_URL = `${API_URL}/partnership`
const getRelationIdUrl = (relationId: Relation['id']) => `${PARTNERSHIP_BASE_URL}/${relationId}`

export function loadRelations() {
  return axios.get(PARTNERSHIP_BASE_URL)
}

export function createRelation(data: Relation) {
  return axios.post(PARTNERSHIP_BASE_URL, data)
}

export function updateRelation(relationId: Relation['id'], data: Relation) {
  return axios.patch(getRelationIdUrl(relationId), data)
}

export function deleteRelation(relationId: Relation['id']) {
  return axios.delete(getRelationIdUrl(relationId))
}
