import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import useEmblaCarousel from 'embla-carousel-react'
import {useElementSize} from '@custom-react-hooks/use-element-size'

import {PrevButton, NextButton, usePrevNextButtons} from './EmblaCarouselArrowButtons'
import {DotButton, useDotButton} from './EmblaCarouselDotButton'

import Image from '@components/molecules/ImagesGalleryModal/components/Image'

import {GalleryImageType} from '@components/molecules/ImagesGalleryModal/ImagesGalleryModal'
import {isMobileDevice} from '@_metronic/assets/ts/_utils'
import useIsMobileDevice from '@hooks/useIsMobileDevice'

type EmblaCarouselProps = {
  images?: GalleryImageType[] | null
  startIndex?: number | null
  readonly?: boolean
}
const EmblaCarousel = ({images, startIndex, readonly}: EmblaCarouselProps) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({startIndex: startIndex || 0})
  const {prevBtnDisabled, nextBtnDisabled, onPrevButtonClick, onNextButtonClick} =
    usePrevNextButtons(emblaApi)

  const {selectedIndex, scrollSnaps, onDotButtonClick} = useDotButton(emblaApi)

  console.log('### selectedIndex', selectedIndex)

  const mobileDevice = useIsMobileDevice()

  useEffect(() => {
    emblaApi?.reInit()
  }, [mobileDevice])

  const [setRef, size] = useElementSize()
  console.log('### useElementSize.size', size)

  if (!images || !images?.length) return null

  return (
    <section className='embla' ref={setRef}>
      <div className='embla__viewport' ref={emblaRef}>
        <div className='embla__container'>
          {images!.map((image, index) => (
            <Image key={index} image={image} readonly={readonly} />
          ))}
        </div>
      </div>

      {!mobileDevice ? (
        <>
          <PrevButtonWrapper topOffset={size.height / 2}>
            <PrevButton onClick={onPrevButtonClick} disabled={prevBtnDisabled} />
          </PrevButtonWrapper>
          <NextButtonWrapper topOffset={size.height / 2}>
            <NextButton onClick={onNextButtonClick} disabled={nextBtnDisabled} />
          </NextButtonWrapper>
          <DotsWrapper className='embla__dots'>
            {scrollSnaps.map((_, index) => (
              <DotButton
                key={index}
                onClick={() => onDotButtonClick(index)}
                className={'embla__dot'.concat(
                  index === selectedIndex ? ' embla__dot--selected' : ''
                )}
              />
            ))}
          </DotsWrapper>
        </>
      ) : null}
    </section>
  )
}

export default React.memo(EmblaCarousel)

const PrevButtonWrapper = styled.div<{topOffset: number}>`
  position: absolute;
  left: 0;
  top: ${({topOffset}) => topOffset - 23}px;
`

const NextButtonWrapper = styled.div<{topOffset: number}>`
  position: absolute;
  right: 0;
  top: ${({topOffset}) => topOffset - 23}px;
`

const DotsWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;

  width: 100%;

  justify-content: center;
`
