import axios from 'axios'
import {Cult} from '@pages/CultsPage/store'

const API_URL = process.env.REACT_APP_API_URL

const LOOK_BASE_URL = `${API_URL}/look`
const getLookIdUrl = (cultId: Cult['id']) => `${LOOK_BASE_URL}/${cultId}`

export function loadCults() {
  return axios.get(LOOK_BASE_URL)
}

export function createCult(data: Cult) {
  return axios.post(LOOK_BASE_URL, data)
}

export function updateCult(cultId: Cult['id'], data: Cult) {
  return axios.patch(getLookIdUrl(cultId), data)
}

export function deleteCult(cultId: Cult['id']) {
  return axios.delete(getLookIdUrl(cultId))
}
