import {useEffect, useState, useRef} from 'react'

const MOBILE_WIDTH = 600

const useIsMobileDevice = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= MOBILE_WIDTH)
  const prevWidth = useRef(window.innerWidth)

  useEffect(() => {
    const handleResize = () => {
      const currWidth = window.innerWidth
      if (currWidth <= MOBILE_WIDTH && prevWidth.current > MOBILE_WIDTH) {
        setIsMobile(true)
      } else if (currWidth > MOBILE_WIDTH && prevWidth.current <= MOBILE_WIDTH) {
        setIsMobile(false)
      }
      prevWidth.current = currWidth
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return isMobile
}

export default useIsMobileDevice
