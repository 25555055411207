/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'

import {useAuth} from '@modules/auth'
import useHostBasedUrl from '@hooks/useHostBasedUrl'

import {Languages} from './Languages'
import {ToggleUserProfileDrawer} from '../userprofile-drawer/ToggleUserProfileDrawer'
import UserStatus from '@components/atoms/UserStatus'

const HeaderUserMenu: FC = () => {
  const intl = useIntl()
  const {currentUser, logout} = useAuth()

  const avatarUrl = useHostBasedUrl(currentUser?.avatar?.url)

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img
              alt='Logo'
              src={avatarUrl ? avatarUrl : 'https://my-engine.ru/modules/users/avatar.png'}
            />
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {currentUser?.first_name}
              <UserStatus user={currentUser!} />
            </div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              {currentUser?.email}
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <ToggleUserProfileDrawer />
      <Languages />

      <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5'>
          {intl.formatMessage({id: 'MENU.SIGN_OUT'})}
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
