/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'

import {usePersonsStore} from '@store/persons'
import {usePhotosStore} from '@store/photos'

import useOnScreen from '@hooks/useOnScreen'
import useHostBasedUrl from '@hooks/useHostBasedUrl'

import {getPerson, uploadImage} from '@api/requests'

import CustomFields from '@components/molecules/CustomFields'
import ReactQuillEditor from '@components/molecules/ReactQuillEditor'

import {AVATAR_IMAGE_TARGET_SIZES} from '@constants/imageUploading'
import {useCultsStore} from '@pages/CultsPage/store'

const EditSimDrawerContent = ({personId, handleValuesChange, editPersonValues}) => {
  const butOpenEditorRef = useRef<any>()
  const [first_name, setFirstName] = useState('')
  const [last_name, setLastName] = useState('')
  const [alive, setAlive] = useState<number>()
  const [deadReason, setDeadReason] = useState('')
  const [species, setSpecies] = useState<string>('')
  const [type, setType] = useState<number>(0)

  const [gender, setGender] = useState<number>()
  const [otherGender, setOtherGender] = useState('')
  const [genderPronoun, setGenderPronoun] = useState<string | undefined>('')

  const [look, setLook] = useState('')
  const [city, setCity] = useState('')
  const [character_traits, setCharacterTraits] = useState<string>('')
  const [life_goals, setLifeGoals] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [avatar, setAvatar] = useState<{url: string} | null>(null)
  const [avatar_id, setAvatarId] = useState<number | null>(null)
  const {selectPhoto} = usePhotosStore()
  const {persons} = usePersonsStore()
  const [customsFields, setCustomFields] = useState<any>([])
  const {cults, loadCults} = useCultsStore()
  useEffect(() => {
    loadCults()
  }, [])

  const editSimDrawerRef = useRef(null)
  const visible = useOnScreen(editSimDrawerRef)

  useEffect(() => {
    if (!visible) {
      setFirstName('')
      setLastName('')
      // setAlive(1)
      setSpecies('')
      setType(0)
      setGender(undefined)
      setOtherGender('')
      setGenderPronoun('')

      setLook('')

      //setCustomFields([])
      setDeadReason('')
      setCharacterTraits('')
      setLifeGoals('')
      setDescription('')
      setAvatar(null)
      setAvatarId(null)
    }
  }, [visible])

  const intl = useIntl()

  // const types = ['Человек', 'Питомец']
  // const sexes = ['Мужской', 'Женский']
  useEffect(() => {
    if (personId) {
      // console.log('### getPerson', personId)
      getPerson(personId).then((response) => {
        // console.log('### response', response)
        const foundPerson = response.data

        if (foundPerson) {
          setFirstName(foundPerson.first_name || '')
          setLastName(foundPerson.last_name || '')
          setAlive(foundPerson.is_alive)
          // @ts-ignore
          setSpecies(foundPerson.species || '')
          setType(foundPerson.type)
          setGender(foundPerson.gender)
          setOtherGender(foundPerson.other_gender)
          setGenderPronoun(foundPerson.params?.genderPronoun)

          setLook(foundPerson.look_id)

          setCharacterTraits(foundPerson.character_traits || '')
          setLifeGoals(foundPerson.life_goals || '')
          setDescription(foundPerson.description || '')
          setAvatar(foundPerson.avatar)
          setDeadReason(foundPerson.deadReason || '')
          // @ts-ignore
          setCustomFields(foundPerson.params ? foundPerson.params.fields : [])
          // @ts-ignore
          setAvatarId(foundPerson.avatar ? foundPerson.avatar.id : null)
        }
      })
    }
  }, [personId])
  useEffect(() => {
    // @ts-ignore
    let p = persons.find((x) => parseInt(x.id) === personId)

    if (p) {
      handleValuesChange({
        avatar,
        first_name,
        species,
        last_name,
        avatar_id,
        gender,
        other_gender: otherGender,
        is_alive: alive,
        deadReason,
        type,
        city,
        look_id: look,
        character_traits,
        life_goals,
        description,
        // @ts-ignore
        params: {...p.params, fields: customsFields ? customsFields : [], genderPronoun},
      })
    }
  }, [
    first_name,
    avatar,
    last_name,
    customsFields,
    alive,
    avatar_id,
    species,
    deadReason,
    gender,
    otherGender,
    genderPronoun,
    type,
    city,
    look,
    character_traits,
    life_goals,
    description,
  ])

  const callBackForCrop = (file) => {
    let reader = new FileReader()
    reader.onload = function (e) {
      if (e && e.target && e.target.result) {
        uploadImage(file, 1).then((response) => {
          // addAvatar(response.data)

          setAvatarId(response.data.id)
          setAvatar(response.data)
        })
      }
      // @ts-ignore
    }
    reader.readAsDataURL(file)
  }

  // const {currentUser} = useAuth()
  // const {show: showSubscriptionModal} = useSubscriptionModalStore()

  const editAvatar = (event) => {
    console.log('### editAvatar', avatarUrl)

    // if (!currentUser?.is_premium) {
    //   showSubscriptionModal()
    //   event.preventDefault(true)
    //   event.stopPropagation()
    //   return
    // }

    if (avatarUrl) {
      // @ts-ignore
      fetch(avatarUrl, {mode: 'no-cors'})
        .then((r) => {
          console.log('### editAvatar.r')
          return r.blob()
        })
        .then((blob) => {
          console.log('### editAvatar.blob')
          selectPhoto(blob, callBackForCrop, 1, AVATAR_IMAGE_TARGET_SIZES, true, true)
        })
        .catch((error) => {
          console.log('### editAvatar.error', error)
        })
    }
  }
  function handleFiles(event) {
    let file = event.currentTarget.files[0]
    selectPhoto(file, callBackForCrop, 1, AVATAR_IMAGE_TARGET_SIZES, false, true)

    event.currentTarget.value = ''

    if (butOpenEditorRef && butOpenEditorRef.current) {
      //@ts-ignore
      butOpenEditorRef.current.click()
    }
  }

  // console.log('### avatar', avatar)
  // console.log('### process.env', process.env.NODE_ENV)

  const avatarUrl = useHostBasedUrl(avatar?.url)
  // console.log('### avatarUrl', avatarUrl)

  return (
    <div
      className='card-body hover-scroll-overlay-y h-400px pt-5'
      id='kt_help_body'
      ref={editSimDrawerRef}
    >
      <button
        ref={butOpenEditorRef}
        style={{pointerEvents: 'none', opacity: 0, position: 'absolute'}}
        data-bs-toggle='modal'
        data-bs-target='#kt_modal_edit_photo'
      />
      {/* begin::Content */}
      <div className='row mb-6'>
        <label className='col-lg-12 col-form-label fw-semibold fs-6'>
          {intl.formatMessage({id: 'USER.PHOTO'})}
        </label>
        <div className='col-lg-12'>
          <div className='image-input image-input-outline' data-kt-image-input='true'>
            <div
              style={{
                backgroundImage: avatarUrl
                  ? //@ts-ignore
                    `url('${avatarUrl}')`
                  : 'url("https://my-engine.ru/modules/users/avatar.png")',
              }}
              className='image-input-wrapper w-125px h-125px'
            />
            <div
              className='d-flex flex-row w-100 justify-content-end column-gap-1'
              style={{left: 0, transform: 'translateX(0) translateY(-10px)'}}
              data-kt-image-input-action='change'
            >
              {avatarUrl && (
                <label
                  className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                  title='Edit avatar'
                  onClick={editAvatar}
                >
                  <i className='ki-duotone ki-setting-2'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                  </i>
                </label>
              )}
              <label
                className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                data-bs-toggle='tooltip'
                title='Change avatar'
              >
                <i className='ki-duotone ki-pencil fs-7'>
                  <span className='path1' />
                  <span className='path2' />
                </i>
                <input
                  onChange={handleFiles}
                  type='file'
                  name='avatar'
                  accept='.png, .jpg, .jpeg'
                />
                <input type='hidden' name='avatar_remove' />
              </label>
            </div>

            <span
              className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
              data-kt-image-input-action='cancel'
              data-bs-toggle='tooltip'
              title='Cancel avatar'
            >
              <i className='ki-duotone ki-cross fs-2'>
                <span className='path1' />
                <span className='path2' />
              </i>
            </span>
          </div>
          <div className='form-text'>{intl.formatMessage({id: 'TREE.ADD.PHOTO.FORMAT'})}</div>
        </div>
      </div>
      <div className='mb-10'>
        <label className='required form-label'>{intl.formatMessage({id: 'USER.FIRST_NAME'})}</label>
        <input
          type='text'
          value={first_name}
          className='form-control'
          placeholder=''
          onChange={(e) => setFirstName(e.currentTarget.value)}
        />
      </div>
      <div className='mb-10'>
        <label className='required form-label'>{intl.formatMessage({id: 'USER.LAST_NAME'})}</label>
        <input
          type='text'
          value={last_name}
          className='form-control'
          placeholder=''
          onChange={(e) => setLastName(e.currentTarget.value)}
        />
      </div>
      <div className='mb-10'>
        <label className=' form-label'>{intl.formatMessage({id: 'SIM.GENDER'})}</label>
        <select
          value={gender}
          className='form-select'
          aria-label='Select example'
          onChange={(e) => setGender(parseInt(e.currentTarget.value))}
        >
          <option>{intl.formatMessage({id: 'SELECT.GENDER'})}</option>
          <option value={0}>{intl.formatMessage({id: 'SELECT.GENDER.MALE'})}</option>
          <option value={1}>{intl.formatMessage({id: 'SELECT.GENDER.FEMALE'})}</option>
          <option value={2}>{intl.formatMessage({id: 'SELECT.GENDER.OTHER'})}</option>
        </select>
      </div>
      {gender === 2 ? (
        <>
          <div className='mb-10'>
            <label className=' form-label'>{intl.formatMessage({id: 'SIM.OTHER_GENDER'})}</label>
            <input
              type='text'
              value={otherGender}
              className='form-control'
              placeholder={intl.formatMessage({id: 'SIM.GENDER.ENTER'})}
              onChange={(e) => setOtherGender(e.currentTarget.value)}
            />
          </div>
          <div className='mb-10'>
            <label className=' form-label'>
              {intl.formatMessage({id: 'SELECT.GENDER.PRONOUNS'})}
            </label>
            <input
              type='text'
              value={genderPronoun}
              className='form-control'
              placeholder=''
              onChange={(e) => setGenderPronoun(e.currentTarget.value)}
            />
          </div>
        </>
      ) : null}
      <div className='mb-10'>
        <label className=' form-label'>{intl.formatMessage({id: 'SIM.CONDITION'})}</label>
        <select
          value={alive}
          className='form-select'
          aria-label='Select example'
          onChange={(e) => setAlive(parseInt(e.currentTarget.value))}
        >
          <option>{intl.formatMessage({id: 'SIM.CONDITION.SELECT'})}</option>
          <option value={1}>{intl.formatMessage({id: 'SIM.CONDITION.ALIVE'})}</option>
          <option value={0}>{intl.formatMessage({id: 'SIM.CONDITION.DEAD'})}</option>
        </select>
      </div>
      {/*{alive === 0 ? (
        <div className='mb-10'>
          <label className=' form-label'>Причина смерти</label>
          <input
            type='email'
            value={deadReason}
            onChange={(e) => setDeadReason(e.currentTarget.value)}
            className='form-control form-control-solid'
            placeholder=''
          />
        </div>
      ) : null}*/}
      <div className='mb-10'>
        <label className=' form-label'>{intl.formatMessage({id: 'SIM.TYPE'})}</label>
        <select
          value={type}
          className='form-select'
          aria-label='Select example'
          onChange={(e) => setType(parseInt(e.currentTarget.value))}
        >
          <option>{intl.formatMessage({id: 'SIM.TYPE.SELECT'})}</option>
          <option value={0}>{intl.formatMessage({id: 'SIM.TYPE.PERSON'})}</option>
          <option value={1}>{intl.formatMessage({id: 'SIM.TYPE.PET'})}</option>
        </select>
      </div>

      <div className='mb-10'>
        <label className='form-label'>{intl.formatMessage({id: 'SIDEBAR.MENU.CULT'})}</label>
        <select
          value={look}
          className='form-control'
          placeholder=''
          onChange={(e) => {
            // @ts-ignore
            setLook(parseInt(e.currentTarget.value))
          }}
        >
          <option>{intl.formatMessage({id: 'SIM.CULT.SELECT'})}</option>
          {cults?.map(({id, name, file}) => (
            <option key={id} value={id}>
              {name}
            </option>
          ))}
        </select>
      </div>

      {/* <div className='mb-10'>
        <label className=' form-label'>{intl.formatMessage({id: 'SIM.RACE'})}</label>
        <input
          type='text'
          value={species}
          className='form-control'
          placeholder=''
          onChange={(e) => setSpecies(e.currentTarget.value)}
        />
      </div>*/}
      <div className='mb-10'>
        <label className=' form-label'>{intl.formatMessage({id: 'PERSON.PERS'})}</label>
        <input
          type='text'
          value={character_traits}
          className='form-control'
          placeholder=''
          onChange={(e) => setCharacterTraits(e.currentTarget.value)}
        />
      </div>
      <div className='mb-10'>
        <label className=' form-label'>{intl.formatMessage({id: 'PERSON.GOALS'})}</label>
        <input
          type='text'
          value={life_goals}
          className='form-control '
          placeholder=''
          onChange={(e) => setLifeGoals(e.currentTarget.value)}
        />
      </div>

      <CustomFields allowCopy values={customsFields} onChange={setCustomFields} />

      <div className='mb-10'>
        <label className='form-label'>{intl.formatMessage({id: 'TREE.ADD.DESCRIPTION'})}</label>
        <ReactQuillEditor initValue={description} setValue={setDescription} />
      </div>
      {/* end::Content */}
    </div>
  )
}

export {EditSimDrawerContent}
