import {create} from 'zustand'

import {createPerson, deletePerson, editPersonAx, getPersons} from '@api/requests'
import {AvatarModel, PersonModel} from '@api/models'

import {RelationsEnum} from '@components/organisms/Tree/constants'
import {RelationType} from '@app/types'
import {UserModel} from '@modules/auth'

type PersonsStore = {
  setActivePersonId: (personId: number | null) => void
  activePersonId: null | number
  createPerson: (newPersonValues: object, avatar: AvatarModel | null) => Promise<void>
  editPerson: (
    personId: number | null | undefined,
    newPersonValues: object,
    extraOptions?: {currentUser?: UserModel}
  ) => Promise<void>
  removePerson: (personId: number | null | undefined) => Promise<void>
  getAllPersons: () => Promise<void>
  persons: PersonModel[]
  updatePersons: (
    persons: PersonModel[],
    withReset?: boolean,
    callback?: (persons?: PersonModel[]) => void
  ) => void
  addPartnerToPerson: (
    personId: number,
    relationPersonId: number,
    relationType: RelationsEnum,
    onError?: (value: string) => void
  ) => void
  deletePartnerOfPerson: (
    personId: number,
    relationPersonId: number,
    onError?: (value: string) => void
  ) => void
  addRelationToPerson: (
    payload: {personId: number; relationId: number; relationType: RelationType},
    onError?: (value: string) => void
  ) => void
  deleteRelationOfPerson: (
    payload: {personId: number; relationId: number; relationType: RelationType},
    onError?: (value: string) => void
  ) => void
}

export const usePersonsStore = create<PersonsStore>((set, get) => ({
  activePersonId: null,
  persons: [],

  updatePersons: (persons: PersonModel[], withReset = false, callback) => {
    // console.log('### updatePersons')
    set((state) => {
      const personIdMap = persons.map(({id}) => id)
      const updatedPersons = [
        ...(withReset ? [] : state.persons.filter(({id}) => !personIdMap.includes(id))),
        ...persons,
      ]

      callback?.(updatedPersons)

      return {
        ...state,
        persons: updatedPersons,
      }
    })
  },
  removePerson: async (personId: number | null | undefined) => {
    if (!personId) return
    await deletePerson(personId).then(() => {
      set((state) => {
        const persons = state.persons.filter((person) => person.id !== personId)

        return {
          ...state,
          persons: [...persons],
        }
      })
    })
  },
  editPerson: async (
    personId: number | null | undefined,
    newPersonValues: object,
    extraOptions?: {currentUser?: UserModel}
  ) => {
    console.log('### editPerson', personId, newPersonValues)
    if (!personId) return

    const persons = get().persons || []
    const personIndex = persons.findIndex((person) => person.id === personId)

    if (personIndex === -1) return Promise.reject()

    const currentPerson = persons[personIndex]

    const updatedPerson = {...currentPerson, ...newPersonValues}
    updatedPerson.is_subtree = updatedPerson.is_subtree || 0
    if (
      (!extraOptions?.currentUser || extraOptions?.currentUser.is_premium) &&
      updatedPerson?.avatar?.url &&
      !updatedPerson?.params?.avatars?.find(({url}) => url === updatedPerson?.avatar?.url)
    ) {
      updatedPerson.params.avatars = [
        updatedPerson?.avatar,
        ...(updatedPerson.params.avatars || []),
      ]
    }

    const response = await editPersonAx(personId, updatedPerson)
    console.log('### editPersonAx.response', response)
    set((state) => {
      const personsClone = [...state.persons]
      personsClone[personIndex] = response.data

      return {
        ...state,
        persons: personsClone,
      }
    })
  },
  addPartnerToPerson: (personId, relationPersonId, relationType, onError) => {
    console.log('### addPartnerToPerson', personId, relationPersonId)
    const persons = get().persons || []
    console.log('### persons', persons)
    const currentPersonIndex = persons.findIndex((p) => p.id === personId)
    console.log('### currentPersonIndex', currentPersonIndex)
    const relationPersonIndex = persons?.findIndex((p) => p.id === relationPersonId)
    console.log('### relationPersonIndex', relationPersonIndex)

    if (currentPersonIndex !== -1 && relationPersonIndex !== -1) {
      const currentPerson = persons[currentPersonIndex]
      console.log('### currentPerson', currentPerson)
      const foundCurrentPersonRelationIndex = (currentPerson.partners || []).findIndex(
        ({partner_id}) => partner_id === relationPersonId
      )

      if (foundCurrentPersonRelationIndex !== -1) {
        persons[currentPersonIndex].partners[foundCurrentPersonRelationIndex].status = relationType
      } else {
        // @ts-ignore
        persons[currentPersonIndex].partners = [
          ...(currentPerson.partners || []).filter(
            ({partner_id, status}) => status && partner_id !== relationPersonId
          ),
          {partner_id: relationPersonId, status: relationType},
        ]
      }

      const relationPerson = persons[relationPersonIndex]
      const foundRelationPersonRelationIndex = (relationPerson.partners || []).findIndex(
        ({partner_id}) => partner_id === personId
      )

      if (foundRelationPersonRelationIndex !== -1) {
        persons[relationPersonIndex].partners[foundRelationPersonRelationIndex].status =
          relationType
      } else {
        // @ts-ignore
        persons[relationPersonIndex].partners = [
          ...(relationPerson.partners || []).filter(({partner_id}) => partner_id !== personId),
          {partner_id: personId, status: relationType},
        ]
      }

      editPersonAx(personId, persons[currentPersonIndex])
        .then((response) => {
          console.log('### editPersonAx.response', response)
          set((state) => ({...state, persons: [...persons]}))
        })
        .catch((error) => {
          console.log('### error', error)
          onError?.(error?.message)
        })
    }
  },
  deletePartnerOfPerson: (personId, relationPersonId, onError) => {
    console.log('### deletePartnerOfPerson', personId, relationPersonId)
    const persons = get().persons || []
    const currentPersonIndex = persons.findIndex((p) => p.id === personId)
    console.log('### currentPersonIndex', currentPersonIndex)
    const relationPersonIndex = persons?.findIndex((p) => p.id === relationPersonId)
    console.log('### relationPersonIndex', relationPersonIndex)

    if (currentPersonIndex !== -1 && relationPersonIndex !== -1) {
      const currentPerson = persons[currentPersonIndex]
      const relationPerson = persons[relationPersonIndex]

      // @ts-ignore
      persons[currentPersonIndex].partners = [
        ...(currentPerson.partners || []).filter(({partner_id}) => partner_id !== relationPersonId),
      ]

      // @ts-ignore
      persons[relationPersonIndex].partners = [
        ...(relationPerson.partners || []).filter(({partner_id}) => partner_id !== personId),
      ]

      // @ts-ignore
      editPersonAx(personId, persons[currentPersonIndex])
        .then((response) => {
          set((state) => ({...state, persons: [...persons]}))
        })
        .catch((error) => {
          console.log('### error', error)
          onError?.(error?.message)
        })
    }
  },
  addRelationToPerson: ({personId, relationId, relationType}, onError) => {
    console.log('### addRelationToPerson', personId, relationId, relationType)
    const persons = get().persons || []
    const currentPersonIndex = persons.findIndex((p) => p.id === personId)
    const relationPersonIndex = persons.findIndex((p) => p.id === relationId)

    if (currentPersonIndex !== -1 && relationPersonIndex !== -1) {
      const currentPersonClone = {...persons[currentPersonIndex]}

      const fieldName = relationType === RelationType.parent ? 'parents' : 'pets'

      // @ts-ignore
      currentPersonClone[fieldName] = [
        ...(currentPersonClone[fieldName] || []).filter((id) => id !== relationId),
        relationId,
      ]

      // console.log('### currentPersonClone', currentPersonClone)
      editPersonAx(personId, currentPersonClone)
        .then((response) => {
          // console.log('### response', response)
          set((state) => {
            const personsClone = [...state.persons]
            personsClone[currentPersonIndex] = response.data
            // console.log('### personsClone', personsClone)
            return {...state, persons: personsClone}
          })
        })
        .catch((error) => {
          console.log('### error', error)
          onError?.(error?.message)
        })
    }
  },
  deleteRelationOfPerson: ({personId, relationId, relationType}, onError) => {
    const persons = get().persons || []
    const currentPersonIndex = persons.findIndex((p) => p.id === personId)
    const relationPersonIndex = persons?.findIndex((p) => p.id === relationId)

    if (currentPersonIndex !== -1 && relationPersonIndex !== -1) {
      const currentPerson = persons[currentPersonIndex]
      const fieldName = relationType === RelationType.parent ? 'parents' : 'pets'

      // @ts-ignore
      persons[currentPersonIndex][fieldName] = [
        ...(currentPerson[fieldName] || []).filter((id) => id !== relationId),
      ]

      editPersonAx(personId, persons[currentPersonIndex])
        .then((response) => {
          set((state) => ({...state, persons: [...persons]}))
        })
        .catch((error) => {
          console.log('### error', error)
          onError?.(error?.message)
        })
    }
  },
  getAllPersons: async () => {
    console.log('### getAllPersons')
    let response = await getPersons()

    set((state) => {
      return {
        ...state,
        persons: response.data,
      }
    })
  },
  setActivePersonId: (personId) => {
    set((state) => ({...state, activePersonId: personId}))
  },
  createPerson: async (newPersonValues, avatar) => {
    let response = await createPerson(newPersonValues)
    set((state) => ({...state, persons: [{...response.data, avatar}, ...state.persons]}))
  },
}))
