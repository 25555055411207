import React, {useMemo} from 'react'
import styled from 'styled-components'

import {useCultsStore} from '@pages/CultsPage/store'
import {useCultDrawerStore} from '@_metronic/partials/layout/cult-drawer/CultDrawer'

import useTreeStyles from '@hooks/useTreeStyles'

import {NODE_AVATAR_SIZE, NODE_HEIGHT} from '@components/organisms/Tree/constants'
import {TreeModel} from '@api/models'

const BUTTON_SIZE = 30

type CultProps = {size?: number; lookId: number; treeId: TreeModel['id']}
const Cult = ({size = NODE_AVATAR_SIZE, lookId, treeId}: CultProps) => {
  // console.log('### size', size)

  const {cults} = useCultsStore()
  const {show} = useCultDrawerStore()

  const koef = size / NODE_AVATAR_SIZE
  // console.log('### koef, size / NODE_HEIGHT', koef, size, NODE_HEIGHT)

  const cult = useMemo(() => {
    // console.log('### lookId', lookId, cults)
    if (lookId) {
      return cults?.find(({id}) => id === lookId)
    }
  }, [cults, lookId])

  const {
    avatarBorderColor,
    avatarBorderWidth = 2,
    avatarBorderRadius,
    avatarBorderType,
  } = useTreeStyles(treeId)
  const avatarBorderWidthParsed = parseInt(`${avatarBorderWidth}`)
  const avatarBorderRadiusParsed =
    ((BUTTON_SIZE * koef) / NODE_AVATAR_SIZE) * parseInt(`${avatarBorderRadius}`)

  if (!cult) return null

  const handleClick = (event) => {
    console.log('### handleClick')
    event.stopPropagation()
    show(cult.id)
  }

  return (
    <Wrapper
      $koef={koef}
      $avatarBorderColor={avatarBorderColor}
      $avatarBorderWidth={avatarBorderWidthParsed}
      $avatarBorderRadius={avatarBorderRadiusParsed}
      $avatarBorderType={avatarBorderType as string}
      onClick={handleClick}
    >
      {cult?.file?.url ? <Img src={cult?.file?.url} alt='' /> : null}
    </Wrapper>
  )
}

export default React.memo(Cult)

const Wrapper = styled.div<{
  $koef: number

  $avatarBorderColor?: string | number
  $avatarBorderWidth: number
  $avatarBorderRadius: number
  $avatarBorderType: string
}>`
  width: ${({$koef}) => BUTTON_SIZE * $koef}px;
  height: ${({$koef}) => BUTTON_SIZE * $koef}px;

  position: absolute;

  bottom: -${BUTTON_SIZE / 2 - 15}px;
  right: -${BUTTON_SIZE / 2 - 7}px;

  border: ${({$avatarBorderType}) => $avatarBorderType}
    ${({$avatarBorderWidth}) => $avatarBorderWidth}px
    ${({$avatarBorderColor}) => $avatarBorderColor};
  border-radius: ${({$avatarBorderRadius}) => $avatarBorderRadius / 2}px;
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.1);
  box-sizing: border-box;

  overflow: hidden;

  cursor: pointer;
`

const Img = styled.img`
  width: 100%;
  height: 100%;

  object-fit: cover;
`
