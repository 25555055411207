import React, {useMemo} from 'react'
import styled from 'styled-components'

import useTreeStyles from '@hooks/useTreeStyles'
import {partnerHeightBasedOnCount} from '@components/organisms/Tree/components/Node/components/Partners/Partners'

const Connector = ({
  connector,
  width,
  height,
  treeId,
  node,
  person,
  inHeirChain,
  heirChainNodes,
  shadow,
}) => {
  const {
    lineColor,
    heirLineColor,
    lineWidth,
    lineShadowWidth,
    lineShadowColor,
    heirLineEnabled: heirLineEnabledString,
    firstNameDisplayingEnabled: firstNameDisplayingEnabledString,
    lastNameDisplayingEnabled: lastNameDisplayingEnabledString,
    diedStatusDisplayingEnabled: diedStatusDisplayingEnabledString,
  } = useTreeStyles(treeId)

  const heirLineEnabled = heirLineEnabledString === 'true'
  const shadowStyleProp = shadow ? `0px 0px ${lineShadowWidth}px ${lineShadowColor}` : 'none'

  const lineWidthParsed = parseInt(`${lineWidth}`)
  const childrenInHeirChain =
    !node?.children?.length || node?.children?.some(({id}) => heirChainNodes?.includes(id))

  const style = useMemo(() => {
    const [x1, y1, x2, y2, {type}] = connector

    const calculatedStyle = {
      backgroundColor: 'transparent',
      width: Math.max(lineWidthParsed, (x2 - x1) * width + lineWidthParsed),
      height: Math.max(lineWidthParsed, (y2 - y1) * height + lineWidthParsed),
      transform: `translate(${x1 * width}px, ${y1 * height}px)`,
    }

    // from parents to children
    if (type === 'children1') {
      const {partners} = person || {}
      const partnersCount = partners?.length // partners?.length > 2 ? partners?.length * 3 : 1.5
      const partnerHeight = partnerHeightBasedOnCount(partnersCount)

      // console.log('### partnersCount', partnersCount, height, partnerHeight)

      const firstNameDisplayingEnabled = firstNameDisplayingEnabledString === 'true'
      const lastNameDisplayingEnabled = lastNameDisplayingEnabledString === 'true'
      const diedStatusDisplayingEnabled = diedStatusDisplayingEnabledString === 'true'

      // let divider = 2
      //
      // if (partners.length === 0) {
      //   if (firstNameDisplayingEnabled) {
      //     divider = divider + 2
      //   }
      //   if (lastNameDisplayingEnabled) {
      //     divider = divider + 2
      //   }
      //   if (diedStatusDisplayingEnabled) {
      //     divider = divider + 2
      //   }
      // }

      // const newHeight = 0 // + 60 / partnersCount // height / divider / partnersCount
      // const newHeight = (partnerHeight * partnersCount - height) / partnersCount
      // const newHeight = 160 - partnerHeight * partnersCount
      // partnersCount
      let newHeight = 21
      if (partnersCount === 1) {
        newHeight = 70
      }
      if (partnersCount === 2) {
        newHeight = 35
      }
      if (partnersCount === 3) {
        newHeight = 20
      }
      if (partnersCount >= 4) {
        newHeight = 10
      }

      // console.log('### newHeight', newHeight)

      const offset = height - newHeight

      calculatedStyle.backgroundColor =
        childrenInHeirChain && heirLineEnabled ? heirLineColor : lineColor
      calculatedStyle.height = newHeight
      calculatedStyle.transform = `translate(${x1 * width}px, ${
        y1 * height + lineWidthParsed + offset
      }px)`

      calculatedStyle.boxShadow = shadowStyleProp
    }

    // from children to parents
    if (type === 'children2') {
      calculatedStyle.backgroundColor = inHeirChain && heirLineEnabled ? heirLineColor : lineColor
      calculatedStyle.height = height - 50
      calculatedStyle.boxShadow = shadowStyleProp
    }

    // between node and partnerschildren4
    if (type === 'children3') {
    }

    if (type === 'children4') {
    }

    // between children
    if (type === 'children5') {
      calculatedStyle.backgroundColor = lineColor
      calculatedStyle.transform = `translate(${x1 * width + 2}px, ${y1 * height}px)`
      calculatedStyle.width = Math.max(lineWidthParsed, (x2 - x1) * width)
      calculatedStyle.boxShadow = shadowStyleProp
    }

    // horizontal from parent to children
    if (type === 'children55' && heirLineEnabled && inHeirChain) {
      calculatedStyle.backgroundColor = heirLineColor
    }

    // horizontal from children to parents
    if (type === 'children6') {
      calculatedStyle.backgroundColor =
        childrenInHeirChain && heirLineEnabled ? heirLineColor : lineColor
      calculatedStyle.boxShadow = shadowStyleProp
    }

    return calculatedStyle
  }, [
    childrenInHeirChain,
    connector,
    diedStatusDisplayingEnabledString,
    firstNameDisplayingEnabledString,
    height,
    heirLineColor,
    heirLineEnabled,
    inHeirChain,
    lastNameDisplayingEnabledString,
    lineColor,
    lineWidthParsed,
    person,
    shadowStyleProp,
    width,
  ])

  return <Line $lineWidth={lineWidthParsed} style={style} />
}

export default React.memo(Connector)

const Line = styled.i`
  border-radius: ${({$lineWidth}) => $lineWidth}px;
  overflow: hidden;
  position: absolute;
  pointer-events: none;
`
