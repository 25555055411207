import React from 'react'
import styled from 'styled-components'
import {GalleryImageType} from '@components/molecules/ImagesGalleryModal/ImagesGalleryModal'

type DescriptionProps = {
  image: GalleryImageType
}
const Description = ({image}: DescriptionProps) => {
  if (!image.description) return null

  return (
    <Wrapper>
      <Shadow>
        <Text>{image.description}</Text>
      </Shadow>
    </Wrapper>
  )
}

export default React.memo(Description)

const Wrapper = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  left: 0;
  bottom: 0;
`

const Shadow = styled.div`
  background-color: rgba(0, 0, 0, 0.4);
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.4);

  display: flex;

  padding: 10px;
  border-radius: 10px;
`

const Text = styled.text`
  font-size: 20px;
`
