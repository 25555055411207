import React, {useEffect, useState} from 'react'
import {create} from 'zustand'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import moment from 'moment/moment'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import {getSubscriptionPlans, updateProfile} from '@api/requests'

import {useAuth} from '@modules/auth'
import {useLang} from '@_metronic/i18n/Metronici18n'

import {SubscriptionPlanType} from '@components/molecules/SubscriptionModal/SubscriptionModal'

import {KTIcon} from '@_metronic/helpers'

const MySwal = withReactContent(Swal)

type CurrentSubscriptionModalStore = {
  visible: boolean
  show: () => void
  hide: () => void
}

export const useCurrentSubscriptionModalStore = create<CurrentSubscriptionModalStore>((set) => ({
  visible: false,
  show: () => set((state) => ({...state, visible: true})),
  hide: () => set((state) => ({...state, visible: false})),
}))

type CurrentSubscriptionProps = {}
const CurrentSubscriptionModal = ({}: CurrentSubscriptionProps) => {
  const language = useLang()
  const {visible, hide} = useCurrentSubscriptionModalStore()

  const handleHide = () => {
    if (visible) {
      hide()
    }
  }

  const {currentUser, setCurrentUser} = useAuth()
  const [plans, setPlans] = useState<SubscriptionPlanType[]>([])
  const [currentPlan, setCurrentPlan] = useState<SubscriptionPlanType>()

  useEffect(() => {
    getSubscriptionPlans().then((response) => {
      const reveivedPlans = response.data
        ?.sort(({duration: durationA}, {duration: durationB}) => durationB - durationA)
        .map(({price, ...plan}) => ({...plan, price: price / 100}))

      setPlans(reveivedPlans)
    })
  }, [])

  useEffect(() => {
    setCurrentPlan(plans.find(({id}) => id === currentUser?.subscribe_plan?.id))
  }, [plans, currentUser])

  const intl = useIntl()

  const handleCancelSubscriptionClick = () => {
    Swal.fire({
      heightAuto: false,
      title: intl.formatMessage({id: 'A.YOU.SURE'}),
      text: intl.formatMessage({id: 'CANCEL.SUB'}),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#50cd89',
      cancelButtonText: intl.formatMessage({id: 'CANCEL'}),
      confirmButtonText: intl.formatMessage({id: 'SURE'}),
    }).then((result) => {
      if (result.isConfirmed) {
        updateProfile({unsubscribe_payment: 1})
          .then((response) => {
            if (currentUser) {
              setCurrentUser({...currentUser, subscribe_plan: null})
            }
            MySwal.fire({
              title: intl.formatMessage({id: 'CANCEL.SUB.DONE'}),
              text: '',
              icon: 'success',
              heightAuto: false,
            })
          })
          .catch((error) => {
            console.log('### error', error)
            MySwal.fire({
              title: intl.formatMessage({id: 'CANCEL.SUB.ERROR'}),
              text: '',
              icon: 'error',
              heightAuto: false,
            })
          })
      }
    })
  }

  return (
    <Modal
      className='modal fade'
      id='kt_modal_select_location'
      data-backdrop='static'
      tabIndex={-1}
      role='dialog'
      show={visible}
      dialogClassName='modal-dialog modal-dialog-centered'
      aria-hidden='true'
      onHide={handleHide}
    >
      <div className='modal-content'>
        <div className='modal-header'>
          <h3 className='modal-title'>{intl.formatMessage({id: 'SUB.INFORM'})}</h3>

          <div className='btn btn-icon btn-sm btn-active-light-primary ms-2' onClick={hide}>
            <KTIcon iconName='cross' className='fs-2x' />
          </div>
        </div>
        <div className='modal-body'>
          {currentUser?.subscribe_till ? (
            <>
              <h5>{intl.formatMessage({id: 'YOU.HAVE.SUB'})}</h5>
              <p>
                {intl.formatMessage({id: 'SUB.DATE'})}:{' '}
                {moment(currentUser?.subscribe_till, 'YYYY-MM-DD').format('DD/MM/YYYY')}
                {language === 'en' &&
                    <a
                        href='https://billing.stripe.com/p/login/00g9Cv5e99FH4so144'
                        target='_blank'
                        className='btn btn-active-light'
                        rel='noreferrer'
                    >
                      {intl.formatMessage({id: 'SUB.CANCEL.BTN'})}
                    </a>
                }
              </p>
            </>
          ) : null}
          {currentPlan ? (
            <>
              <p>
                {intl.formatMessage({id: 'SUB.PRICE'})} {currentPlan.price}₽{' '}
                {intl.formatMessage({id: 'SUB.DURATION'})} {currentPlan.duration}{' '}
                {intl.formatMessage({id: 'SUB.MONTH'})}
                <br />
                {intl.formatMessage({id: 'SUB.CANCEL.CLICK'})}
              </p>
              {language === 'ru' ? (
                <button className='btn btn-active-light' onClick={handleCancelSubscriptionClick}>
                  {intl.formatMessage({id: 'SUB.CANCEL.BTN'})}
                </button>
              ) : (
                <a
                  href='https://billing.stripe.com/p/login/00g9Cv5e99FH4so144'
                  target='_blank'
                  className='btn btn-active-light'
                  rel='noreferrer'
                >
                  {intl.formatMessage({id: 'SUB.CANCEL.BTN'})}
                </a>
              )}
            </>
          ) : null}
        </div>
        <div className='modal-footer'>
          <button
            type='button'
            className='btn btn-active-light'
            data-bs-dismiss='modal'
            onClick={hide}
          >
            {intl.formatMessage({id: 'ACTIONS.CLOSE'})}
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default React.memo(CurrentSubscriptionModal)
