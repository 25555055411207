/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {useIntl} from 'react-intl'
import {create} from 'zustand'
import styled from 'styled-components'

import ViewTreeAuthorDrawerContent from './components/ViewTreeAuthorDrawerContent'
import {KTIcon} from '@_metronic/helpers'

import {TreeModel} from '@api/models'

type ViewTreeAuthorDrawerStore = {
  opened: boolean
  tree: TreeModel | null
  show: (tree: TreeModel) => void
  hide: () => void
}

export const useViewTreeAuthorDrawerStore = create<ViewTreeAuthorDrawerStore>((set) => ({
  opened: false,
  tree: null,
  show: (tree) => set((state) => ({...state, opened: true, tree})),
  hide: () => set((state) => ({...state, opened: false, tree: null})),
}))

const ViewTreeAuthorDrawer = ({readonly = false}) => {
  const intl = useIntl()

  const {tree, opened, hide} = useViewTreeAuthorDrawerStore()

  return (
    <>
      {opened ? <Overlay onClick={hide} /> : null}
      <Wrapper className='bg-body' $hidden={!opened}>
        {/* begin::Card */}
        <div className='card shadow-none rounded-0 w-100'>
          {/* begin::Header */}
          <div className='card-header' id='kt_help_header'>
            <h5 className='card-title fw-bold'>
              {intl.formatMessage({id: 'TREE.CARD.AUTHOR_TITLE'})}
            </h5>

            <div className='card-toolbar'>
              <button
                type='button'
                className='btn btn-sm btn-icon explore-btn-dismiss me-n5'
                onClick={hide}
              >
                <KTIcon iconName='cross' className='fs-2' />
              </button>
            </div>
          </div>

          {/* begin::Body */}
          {tree ? <ViewTreeAuthorDrawerContent tree={tree} /> : null}

          {/* end::Body */}
        </div>
        {/* end::Card */}
      </Wrapper>
    </>
  )
}

export {ViewTreeAuthorDrawer}

const Wrapper = styled.div<{$hidden: boolean}>`
  display: ${({$hidden}) => ($hidden ? 'none' : 'flex')};

  position: fixed;
  top: 0;
  right: 0;

  width: 525px;
  height: 100%;

  z-index: 106;

  @media (max-width: 800px) {
    width: 350px;
  }
`

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.25);

  z-index: 105;
`
