import React from 'react'
import styled from 'styled-components'

import useHostBasedUrl from '@hooks/useHostBasedUrl'
import useIsMobileDevice from '@hooks/useIsMobileDevice'

import Description from '@components/molecules/ImagesGalleryModal/components/Description'

import {GalleryImageType} from '@components/molecules/ImagesGalleryModal/ImagesGalleryModal'

type ImageProps = {image: GalleryImageType; readonly?: boolean}
const Image = ({image, readonly}: ImageProps) => {
  const url = useHostBasedUrl(image.url)
  console.log('### readonly', readonly)
  const mobileDevice = useIsMobileDevice()
  console.log('### mobileDevice', mobileDevice)

  return (
    <div className='embla__slide'>
      <Photo src={url} mobile={mobileDevice} />
      <Description image={image} />
    </div>
  )
}

export default React.memo(Image)

const WINDOW_HEIGHT = window.innerWidth
const WINDOW_WIDTH = window.innerHeight
const PHOTO_PADDING = 20
const Photo = styled.img<{mobile: boolean}>`
  width: ${({mobile}) => WINDOW_HEIGHT - (mobile ? 0 : PHOTO_PADDING * 2)}px;
  height: ${({mobile}) => WINDOW_WIDTH - (mobile ? 0 : PHOTO_PADDING * 2)}px;

  padding: ${({mobile}) => (mobile ? 0 : PHOTO_PADDING)}px;

  object-fit: contain;
  object-position: center;
`
