import React, {useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import styled from 'styled-components'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

import useHostBasedUrl from '@hooks/useHostBasedUrl'

import {Cult, useCultsStore} from '@pages/CultsPage/store'

const CultDrawerContent = ({cultId, readonly = false}) => {
  const intl = useIntl()
  const butOpenEditorRef = useRef<any>()

  const {cults} = useCultsStore()

  const [loading, setLoading] = useState(false)

  const [cult, setCult] = useState<Cult | null>(null)
  const [fields, setFields] = useState([])

  useEffect(() => {
    const foundCult = cults?.find(({id}) => id === cultId)

    if (foundCult) {
      setCult(foundCult)
      // @ts-ignore
      setFields(foundCult.params ? foundCult.params.fields : [])
    }
  }, [cultId, cults])

  useEffect(() => {
    if (!cultId) {
      setCult(null)
      setFields([])
    }
  }, [cultId])

  const avatarUrl = useHostBasedUrl(cult?.file?.url)

  return (
    <div className='pt-5' style={{overflowX: 'hidden'}}>
      <button
        ref={butOpenEditorRef}
        style={{pointerEvents: 'none', opacity: 0, position: 'absolute'}}
        data-bs-toggle='modal'
        data-bs-target='#kt_modal_edit_photo'
      />
      <div className='d-flex flex-column text-center mb-9 px-9'>
        <div className='symbol symbol-150px mb-4'>
          {/* @ts-ignore*/}
          {avatarUrl ? (
            <img src={avatarUrl} className='' alt='' style={{objectFit: 'cover'}} />
          ) : (
            <img
              src='https://my-engine.ru/modules/users/avatar.png'
              alt=''
              style={{objectFit: 'cover'}}
            />
          )}
          {loading ? (
            <div className='d-flex flex-fill justify-content-center'>
              <span className='indicator-progress mt-5' style={{display: 'block'}}>
                {intl.formatMessage({id: 'ACTIONS.WAIT'})}{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            </div>
          ) : null}
        </div>

        <div className='text-center'>
          <span className='text-gray-800 fw-bold text-hover-primary fs-1'>{cult && cult.name}</span>
        </div>
      </div>

      {cult && (
        <div>
          {fields?.map((x, k) => (
            <div className='row mb-7' key={k}>
              <div className='col-lg-4 fw-semibold text-muted fs-6'>{x[0]}</div>
              <div className='col-lg-8 fw-semibold text-gray-800 fs-6'>{x[1]}</div>
            </div>
          ))}

          {cult.description ? (
            <Description
              className='lead ql-editor'
              dangerouslySetInnerHTML={{__html: cult.description}}
            />
          ) : null}
        </div>
      )}
    </div>
  )
}

export {CultDrawerContent}

const Description = styled.div`
  & blockquote {
    border-left: 4px solid #ccc;
    margin-bottom: 5px;
    margin-top: 5px;
    padding-left: 16px;
  }
`
