import React from 'react'
import styled from 'styled-components'
import {create} from 'zustand'

import '@components/molecules/ImagesGalleryModal/components/embla.css'

import EmblaCarousel from '@components/molecules/ImagesGalleryModal/components/EmblaCarousel'

import {KTIcon} from '@_metronic/helpers'

export type GalleryImageType = {id: number; url: string; description?: string}

type Payload = {
  images?: GalleryImageType[] | null
  startIndex?: number | null
}

type ImagesGalleryModalStore = {
  opened: boolean
  payload: Payload | null
  open: (payload: Payload) => void
  close: () => void
}
export const useImagesGalleryModalStore = create<ImagesGalleryModalStore>((set) => ({
  opened: false,
  payload: null,
  open: (payload) => set((state) => ({...state, payload, opened: true})),
  close: () => set((state) => ({...state, opened: false, payload: null})),
}))

type ImagesGalleryModalProps = {readonly?: boolean}
const ImagesGalleryModal = ({readonly}: ImagesGalleryModalProps) => {
  const {opened, close, payload} = useImagesGalleryModalStore()

  return (
    <>
      {opened ? <Overlay onClick={close} /> : null}
      <Wrapper className='bg-body' $hidden={!opened}>
        <CloseButton type='button' className='btn btn-sm btn-icon me-n5' onClick={close}>
          <KTIcon iconName='cross' className='fs-2' />
        </CloseButton>
        {payload ? (
          <EmblaCarousel images={payload.images} startIndex={payload.startIndex} readonly />
        ) : null}
      </Wrapper>
    </>
  )
}

export default React.memo(ImagesGalleryModal)

const Wrapper = styled.div<{$hidden: boolean}>`
  display: ${({$hidden}) => ($hidden ? 'none' : 'flex')};

  position: fixed;
  top: 0;
  right: 0;

  width: 100%;
  height: 100%;

  z-index: 106;
`

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.25);

  z-index: 105;
`

const CloseButton = styled.button`
  position: absolute;
  right: 25px;
  top: 10px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.4) !important;
  background-color: rgba(255, 255, 255, 0.1);

  z-index: 1;
`
