import React from 'react'
import styled from 'styled-components'
import {useIntl} from 'react-intl'
import Swal from 'sweetalert2'

import {useTreesStore} from '@store/trees'
import {useAuth} from '@modules/auth'
import useIsFirstOrImportedTree from '@hooks/useIsFirstOrImportedTree'

import {PersonsModalMode, usePersonsModalStore} from '@components/molecules/PersonsModal'
import {useSubscriptionModalStore} from '@components/molecules/SubscriptionModal/SubscriptionModal'

import {ReactComponent as TrashIcon} from '@assets/icons/svg/trash-icon.svg'
import {NODE_HEIGHT} from '@components/organisms/Tree/constants'

type EditButtonsProps = {
  treeId: number
  node: {id: number; children: number[]; parents: number[]}
  person: {person_id: number}
  hover: boolean
}
const EditButtons = ({treeId, node, person, hover}: EditButtonsProps) => {
  const intl = useIntl()
  const isFirstOrImportedTree = useIsFirstOrImportedTree(treeId)

  const {show: showPersonSelectModal} = usePersonsModalStore()
  const {busy, addEmptyChildren, deleteNode, addEmptyRootParent, deleteRootNode} = useTreesStore()

  const {currentUser} = useAuth()
  const {show: showSubscriptionModal} = useSubscriptionModalStore()

  const handleEditPress = (e) => {
    e.preventDefault(true)
    e.stopPropagation()

    if (!currentUser?.is_premium && !isFirstOrImportedTree) {
      showSubscriptionModal()
      return
    }

    showPersonSelectModal({treeId, nodeId: node.id}, PersonsModalMode.personForNodeSelect)
  }

  const handleBottomPlusPress = (event) => {
    event.preventDefault(true)
    event.stopPropagation()

    if (!currentUser?.is_premium && !isFirstOrImportedTree) {
      showSubscriptionModal()
      return
    }

    addEmptyChildren(treeId, node.id)
  }

  const handleHeartPress = (event) => {
    event.preventDefault(true)
    event.stopPropagation()

    showPersonSelectModal({personId: person.person_id}, PersonsModalMode.partnerSelect)
  }

  const handleDeletePress = (e) => {
    e.preventDefault(true)
    e.stopPropagation()

    Swal.fire({
      heightAuto: false,
      title: intl.formatMessage({id: 'A.YOU.SURE'}),
      text: intl.formatMessage({id: 'YOU.WHANT'}),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#50cd89',
      cancelButtonText: intl.formatMessage({id: 'CANCEL'}),
      confirmButtonText: intl.formatMessage({id: 'DELETE'}),
    }).then((result) => {
      if (result.isConfirmed) {
        deleteNode(treeId, node.id)
      }
    })
  }

  const handleDeleteRootPress = (e) => {
    e.preventDefault(true)
    e.stopPropagation()

    Swal.fire({
      heightAuto: false,
      title: intl.formatMessage({id: 'A.YOU.SURE'}),
      text: intl.formatMessage({id: 'YOU.WHANT'}),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#50cd89',
      cancelButtonText: intl.formatMessage({id: 'CANCEL'}),
      confirmButtonText: intl.formatMessage({id: 'DELETE'}),
    }).then((result) => {
      if (result.isConfirmed) {
        deleteRootNode(treeId, node.id)
      }
    })
  }

  const handleTopPlusPress = (event) => {
    event.preventDefault(true)
    event.stopPropagation()

    if (!currentUser?.is_premium && !isFirstOrImportedTree) {
      showSubscriptionModal()
      return
    }

    addEmptyRootParent(treeId, node.id)
  }

  const {setHeirNodeId} = useTreesStore()
  const handleHeirPress = () => {
    setHeirNodeId(treeId, node.id)
  }

  return (
    <>
      <EditButton disabled={busy} $visible={hover} onClick={handleEditPress}>
        <Icon src={require(`../assets/icons/edit.png`)} />
      </EditButton>
      <PlusBottomButton disabled={busy} $visible={hover} onClick={handleBottomPlusPress}>
        <Icon src={require(`../assets/icons/plus.png`)} />
      </PlusBottomButton>
      {person?.person_id ? (
        <HeartButton disabled={busy} $visible={hover} onClick={handleHeartPress}>
          <Icon src={require(`../assets/icons/heart.png`)} />
        </HeartButton>
      ) : null}

      {!node.children?.length && node.parents?.length > 0 ? (
        <TrashButton disabled={busy} $visible={hover} onClick={handleDeletePress}>
          <TrashIcon height={20} />
        </TrashButton>
      ) : null}

      {node.children?.length === 1 && !node.parents?.length ? (
        <DeleteSelfButton disabled={busy} $visible={hover} onClick={handleDeleteRootPress}>
          <TrashIcon height={20} />
        </DeleteSelfButton>
      ) : null}

      {!node.parents?.length ? (
        <PlusTopButton disabled={busy} $visible={hover} onClick={handleTopPlusPress}>
          <Icon src={require(`../assets/icons/plus.png`)} />
        </PlusTopButton>
      ) : null}

      {node.parents?.length ? <HeirButton onClick={handleHeirPress} /> : null}
    </>
  )
}

export default React.memo(EditButtons)

const BUTTON_SIZE = 32
const BUTTON_ICON_SIZE = 16

const Button = styled.button<{$visible: boolean}>`
  z-index: 3;
  width: ${BUTTON_SIZE}px;
  height: ${BUTTON_SIZE}px;
  border-radius: 50%;
  background-color: #46bc60;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  transition: opacity;
  transition-duration: 100ms;

  opacity: ${({$visible, disabled}) => ($visible ? (disabled ? 0.2 : 0.8) : 0)};

  &:hover {
    opacity: ${({disabled}) => (disabled ? 0.2 : 1)};
  }

  border: none;
`

const Icon = styled.img`
  width: ${BUTTON_ICON_SIZE}px;
  height: ${BUTTON_ICON_SIZE}px;
`

const EditButton = styled(Button)`
  position: absolute;
  left: -${BUTTON_SIZE / 2}px;
  top: ${NODE_HEIGHT / 2 - BUTTON_SIZE / 2}px;
`

const PlusBottomButton = styled(Button)`
  position: absolute;
  left: ${NODE_HEIGHT / 2 - BUTTON_SIZE / 2}px;
  bottom: -${BUTTON_SIZE / 2}px;
`

const PlusTopButton = styled(Button)`
  position: absolute;
  left: ${NODE_HEIGHT / 2 - BUTTON_SIZE / 2}px;
  top: -${BUTTON_SIZE / 2}px;
`

const HeartButton = styled(Button)`
  position: absolute;
  left: ${NODE_HEIGHT - BUTTON_SIZE / 2 - 4}px;
  top: ${NODE_HEIGHT / 2 - BUTTON_SIZE / 2}px;
`

const TrashButton = styled(Button)<{$size: number}>`
  position: absolute;
  left: ${NODE_HEIGHT / 2 - BUTTON_SIZE / 2}px;
  top: -${BUTTON_SIZE / 2 - BUTTON_SIZE / 2 + 10}px;

  background-color: crimson;
`

const DeleteSelfButton = styled(TrashButton)`
  left: ${NODE_HEIGHT / 2 - BUTTON_SIZE / 2}px;
  top: ${NODE_HEIGHT / 2 - BUTTON_SIZE / 2}px;
`

const HEIR_BUTTON_SIZE = NODE_HEIGHT * 0.6
const HEIR_OFFSET = NODE_HEIGHT * 0.009
const HeirButton = styled.div`
  position: absolute;
  left: ${NODE_HEIGHT / 2 - HEIR_BUTTON_SIZE / 2 - HEIR_OFFSET}px;
  top: ${NODE_HEIGHT / 2 - HEIR_BUTTON_SIZE / 2 - HEIR_OFFSET}px;

  width: ${HEIR_BUTTON_SIZE}px;
  height: ${HEIR_BUTTON_SIZE}px;

  border-radius: 50%;

  cursor: pointer;

  opacity: 0.5;
  &:hover {
    opacity: 0.8;
  }
`
