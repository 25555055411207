import {AUTH_LINK} from '@app/constants/links'
import React, {useMemo} from 'react'
import {useIntl} from 'react-intl'

const SignInLink = ({refToken}: {refToken: string | undefined}) => {
  const intl = useIntl()

  const authLink = useMemo(() => `${AUTH_LINK}${refToken ? `?ref=${refToken}` : ''}`, [refToken])

  return (
    <a
      className='btn btn-sm btn-outline btn-outline-dashed btn-outline-success btn-active-light-success me-2'
      target='_blank'
      href={authLink}
      rel='noreferrer'
    >
      {intl.formatMessage({id: 'AUTH.LOGIN.TITLE'})}
    </a>
  )
}

export default React.memo(SignInLink)
