import React, {useEffect, useRef, useState} from 'react'
import clsx from 'clsx'

import useReactionsStore from '@store/useReactionsStore'

import {useAuth} from '@modules/auth'
import {
  getComments,
  getReaction,
  putReaction,
  ReactionTargetType,
  ReactionTypes,
} from '@api/requests'
import {CommentParamTypes, useMessengerModalStore} from '@components/molecules/MessengerModal/store'
import {Article} from '@api/models'
import useGetChat from '@hooks/useGetChat'

const ANON_LIKED_KEY = '@anon_liked'

type ArticleCommentsAndLikesProps = {
  article?: Article
}
const ArticleCommentsAndLikes = ({article}: ArticleCommentsAndLikesProps) => {
  const {currentUser} = useAuth()

  const buttonRef = useRef<HTMLSpanElement>(null)

  const anonStorageKey = `${ANON_LIKED_KEY}_article_${article?._id}`

  const {show} = useMessengerModalStore()
  const {chat, loadChat} = useGetChat(CommentParamTypes.article, article?._id)
  const commentsCount = chat?.comments.length || 0
  const handleMessengerOpen = () => {
    console.log('### handleMessengerOpen')
    if (article) {
      show({type: CommentParamTypes.article, article})
    }
  }

  const [liked, setLiked] = useState(false)
  const [likedByAnonymous, setLikedByAnonymous] = useState(false)
  const [likesCount, setLikesCount] = useState(0)
  const handleToggleLike = () => {
    if (article) {
      if (currentUser) {
        if (liked) {
          putReaction({
            id: article?._id,
            type: ReactionTargetType.article,
            value: ReactionTypes.dislike,
          }).then(() => {
            setLiked(false)
            setLikesCount((count) => count - 1)
          })
        } else {
          putReaction({
            id: article?._id,
            type: ReactionTargetType.article,
            value: ReactionTypes.like,
          }).then(() => {
            setLiked(true)
            setLikesCount((count) => count + 1)
          })
        }
      } else {
        // check in local storage
        const alreadyLiked = localStorage.getItem(anonStorageKey) === 'true'
        // like it if don't liked already
        if (!alreadyLiked) {
          putReaction({
            id: article?._id,
            type: ReactionTargetType.article,
            value: ReactionTypes.like,
          }).then(() => {
            setLikedByAnonymous(true)
            setLikesCount((count) => count + 1)
            localStorage.setItem(anonStorageKey, 'true')
          })
        }
      }
    }
  }

  useEffect(() => {
    if (article) {
      getReaction({id: article._id, type: ReactionTargetType.article}).then((response) => {
        console.log('### getReaction.response', response)
        if (response.data) {
          setLikesCount(response.data.find(({type}) => type === ReactionTypes.like)?.count || 0)
        }
      })

      loadChat()

      // getComments({id: article?._id, type: ReactionTargetType.article}).then((response) => {
      //   console.log('### getComments.response', response)
      //   if (response.data) {
      //     setCommentsCount(response.data.comments.length)
      //   }
      //   // setMessages(response.data)
      // })
    }
  }, [article])

  // DEBUG PURPOSE
  const {
    refreshReactions,
    data: {[ReactionTypes.like]: likeReactions},
  } = useReactionsStore()
  useEffect(() => {
    if (!likeReactions?.loading) {
      refreshReactions(ReactionTypes.like)
    }
  }, [])

  useEffect(() => {
    if (likeReactions?.reactions) {
      setLiked(
        likeReactions.reactions.some(
          (reaction) => reaction.type === ReactionTargetType.article && reaction.id === article?._id
        )
      )
    }
  }, [likeReactions])

  return (
    <ul className='nav flex-shrink-0'>
      <li className='nav-item p-0'>
        <span
          ref={buttonRef}
          className='nav-link btn btn-sm btn-color-gray-600 btn-active-color-primary btn-active-light-primary fw-bold p-2'
          onClick={handleMessengerOpen}
        >
          <i className='ki-duotone ki-message-text-2 fs-2 me-1'>
            <span className='path1' />
          </i>
          {commentsCount}
        </span>
      </li>
      <li className='nav-item p-0'>
        <span
          className='nav-link btn btn-sm btn-color-gray-600 btn-active-color-primary btn-active-light-primary fw-bold p-2'
          onClick={handleToggleLike}
        >
          <i
            className={clsx(
              'ki-duotone ki-heart fs-2 me-1',
              (liked || likedByAnonymous) && 'text-danger'
            )}
          >
            <svg
              width='20'
              height='20'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M18.3721 4.65439C17.6415 4.23815 16.8052 4 15.9142 4C14.3444 4 12.9339 4.73924 12.003 5.89633C11.0657 4.73913 9.66 4 8.08626 4C7.19611 4 6.35789 4.23746 5.62804 4.65439C4.06148 5.54462 3 7.26056 3 9.24232C3 9.81001 3.08941 10.3491 3.25153 10.8593C4.12155 14.9013 9.69287 20 12.0034 20C14.2502 20 19.875 14.9013 20.7488 10.8593C20.9109 10.3491 21 9.81001 21 9.24232C21.0007 7.26056 19.9383 5.54462 18.3721 4.65439Z'
                fill='currentColor'
              />
            </svg>
          </i>
          {likesCount}
        </span>
      </li>
    </ul>
  )
}

export default React.memo(ArticleCommentsAndLikes)
