import React, {useState} from 'react'
import styled from 'styled-components'
import clsx from 'clsx'

import {useAuth} from '@modules/auth'

import useHostBasedUrl from '@hooks/useHostBasedUrl'

import UserStatus from '@components/atoms/UserStatus'
import Citation from '@components/molecules/MessengerModal/components/Citation'
import {MessagesInfo, MessageType} from '@components/molecules/MessengerModal/types'

import {KTIcon} from '@_metronic/helpers'

type MessageProps = {
  authors: MessagesInfo['authors']
  message: MessageType
  citation?: MessageType
  owner: boolean
  onDelete: (commentId: number) => void
  onSelect?: (commentId: number) => void
}
const Message = ({authors, message, citation, owner, onDelete, onSelect}: MessageProps) => {
  const {currentUser} = useAuth()
  const {id, message: text, user_id} = message

  const author = user_id === currentUser?.id ? currentUser : authors[user_id]
  const ownComment = author.id === currentUser?.id
  const userName = [author.first_name, author.last_name].join(' ')

  const messageType = currentUser?.id === author?.id ? 'out' : 'in'

  const handleDeleteClick = () => {
    onDelete(id)
  }

  const [hovered, setHovered] = useState(false)

  const authorUrl = useHostBasedUrl(author.avatar?.url)

  return (
    <div
      className={clsx(
        'd-flex',
        messageType === 'in' ? 'justify-content-start' : 'justify-content-end',
        'mb-6'
      )}
      onMouseOver={() => setHovered(true)}
      onMouseOut={() => setHovered(false)}
    >
      <div
        className={clsx(
          'd-flex flex-column align-items',
          `align-items-${messageType === 'in' ? 'start' : 'end'}`
        )}
      >
        <div
          className={clsx(
            'd-flex align-items-center mb-2',
            messageType !== 'in' && 'flex-row-reverse'
          )}
        >
          <div className='symbol  symbol-35px symbol-circle '>
            <img alt='Pic' src={authorUrl ?? 'https://my-engine.ru/modules/users/avatar.png'} />
          </div>
          <div className='ms-3'>
            <User className='align-items-center' onClick={() => onSelect?.(id)}>
              <span className='fs-5 fw-bolder text-gray-900 me-1'>{userName}</span>
              <UserStatus user={author} hideFree />
              {messageType === 'in' && hovered && (
                <span className='text-success ms-2 cursor-pointer text-hover-primary'>
                  Ответить
                </span>
              )}
            </User>
          </div>
          {hovered && (owner || ownComment) ? (
            <DeleteButton
              className='btn d-flex justify-content-center align-items-center'
              onClick={handleDeleteClick}
            >
              <KTIcon iconName='trash' className='fs-2' />
            </DeleteButton>
          ) : null}
        </div>

        <div
          className={clsx(
            'p-5 rounded',
            messageType === 'in' ? 'bg-light-info' : 'bg-light-primary',
            'text-dark fw-bold mw-lg-400px',
            `text-start`
          )}
          data-kt-element='message-text'
        >
          {citation ? <Citation citation={citation} author={authors[citation.user_id]} /> : null}
          {text}
        </div>
      </div>
    </div>
  )
}

export default React.memo(Message)

const User = styled.span`
  display: flex;
`

const DeleteButton = styled.button`
  height: 30px;
  width: 40px;

  i {
    padding: 0;
  }

  &:hover {
    background-color: #eee;
  }
`
