/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import Resizer from 'react-image-file-resizer'

import {uploadImage} from '@api/requests'
import useOnScreen from '@hooks/useOnScreen'
import styled from 'styled-components'

const AddEditRelationDrawerContent = ({initialValues, onAvatarChange, onValuesChange}) => {
  const intl = useIntl()
  const butOpenEditorRef = useRef<any>()

  const [name, setName] = useState(initialValues?.name_en || initialValues?.name_ru)

  const [nameInputValue, setNameInputValue] = useState(name)

  useEffect(() => {
    setNameInputValue(name)
  }, [name])

  useEffect(() => {
    const timeout = setTimeout(() => {
      setName(nameInputValue)
    }, 300)

    return () => clearTimeout(timeout)
  }, [nameInputValue])

  const [avatar, setAvatar] = useState(initialValues?.file?.url)
  const [avatar_id, setAvatarId] = useState<number | null>(initialValues?.file?.id)

  const addRelationDrawerRef = useRef(null)
  const visible = useOnScreen(addRelationDrawerRef)

  useEffect(() => {
    if (visible) {
      setName((initialValues?.name_en || initialValues?.name_ru) ?? '')

      setAvatar(initialValues?.file?.url)
      setAvatarId(initialValues?.file?.id)
    }
  }, [visible, initialValues])

  useEffect(() => {
    onValuesChange({
      name_en: name,
      name_ru: name,
      avatar_id,
    })
  }, [name, avatar_id])

  function handleFiles(event) {
    let file = event.currentTarget.files[0]

    if (file) {
      try {
        Resizer.imageFileResizer(
          file,
          200,
          200,
          'PNG',
          100,
          0,
          (uri) => {
            console.log(uri)

            if (uri) {
              uploadImage(uri, 1).then((response) => {
                setAvatarId(response.data.id)
                // @ts-ignore
                onAvatarChange(response.data)
              })
            }
            // @ts-ignore
            setAvatar(uri)
          },
          'base64'
          // 200,
          // 200
        )
      } catch (err) {
        console.log(err)
      }
    }
  }

  return (
    <div
      className='card-body hover-scroll-overlay-y h-400px pt-5'
      id='kt_help_body'
      ref={addRelationDrawerRef}
    >
      {/* @ts-ignore */}
      <button
        ref={butOpenEditorRef}
        style={{pointerEvents: 'none', opacity: 0, position: 'absolute'}}
        data-bs-toggle='modal'
        data-bs-target='#kt_modal_edit_photo'
      />
      <div className='row mb-6'>
        <label className='col-lg-12 col-form-label fw-semibold fs-6'>
          {intl.formatMessage({id: 'TREE.ADD.PHOTO'})}
        </label>
        <div className='col-lg-12'>
          <div className='image-input image-input-outline' data-kt-image-input='true'>
            <Image>
              <div
                style={{
                  backgroundImage: avatar
                    ? `url('${avatar}')`
                    : 'url("https://my-engine.ru/modules/users/avatar.png")',
                }}
                className='image-input-wrapper w-125px h-125px'
              />
            </Image>
            <label
              className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
              data-kt-image-input-action='change'
              data-bs-toggle='tooltip'
              title='Change avatar'
            >
              <i className='ki-duotone ki-pencil fs-7'>
                <span className='path1' />
                <span className='path2' />
              </i>
              <input onChange={handleFiles} type='file' name='icon' accept='.png' />
              <input type='hidden' name='avatar_remove' />
            </label>
            <span
              className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
              data-kt-image-input-action='cancel'
              data-bs-toggle='tooltip'
              title='Cancel avatar'
            >
              <i className='ki-duotone ki-cross fs-2'>
                <span className='path1' />
                <span className='path2' />
              </i>
            </span>
          </div>
          <div className='form-text'>
            {intl.formatMessage({id: 'CUSTOM_ICON.ADD.PHOTO.FORMAT'})}
          </div>
        </div>
      </div>
      <div className='mb-10'>
        <label className='required form-label'>{intl.formatMessage({id: 'ADD.FIELD.NAME'})}</label>
        <input
          type='text'
          value={nameInputValue}
          onChange={(e) => setNameInputValue(e.currentTarget.value)}
          className='form-control'
          placeholder=''
        />
      </div>

      {/* end::Content */}
    </div>
  )
}

export {AddEditRelationDrawerContent}

const IMAGE_SIZE = 125
const Image = styled.div`
  width: ${IMAGE_SIZE}px;
  height: ${IMAGE_SIZE}px;

  overflow: hidden;
`
