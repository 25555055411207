/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import styled from 'styled-components'

import {KTIcon} from '@_metronic/helpers'

import {CommentParamTypes, useMessengerModalStore} from './store'

import Messenger from './components/Messenger'

type MessengerProps = {
  refToken?: string
}

const MessengerModal = ({refToken}: MessengerProps) => {
  const {opened, hide, payload, sentCallback} = useMessengerModalStore()
  const tree = payload?.type === CommentParamTypes.tree ? payload.tree : null
  const article = payload?.type === CommentParamTypes.article ? payload.article : null

  return (
    <>
      {opened ? <Overlay onClick={hide} /> : null}
      <Wrapper className='bg-body' $hidden={!opened}>
        {/* begin::Card */}
        <div className='card shadow-none rounded-0 w-100'>
          {/* begin::Header */}
          <div className='card-header' id='kt_help_header'>
            <h5 className='card-title fw-bold'>
              {tree?.name}
              {article?.name}
            </h5>

            <div className='card-toolbar'>
              <button
                type='button'
                className='btn btn-sm btn-icon explore-btn-dismiss me-n5'
                onClick={hide}
              >
                <KTIcon iconName='cross' className='fs-2' />
              </button>
            </div>
          </div>
          {/* end::Header */}

          {/* begin::Body */}
          <Messenger visible={opened} refToken={refToken} />
          {/* end::Body */}
        </div>
        {/* end::Card */}
      </Wrapper>
    </>
  )
}

export default MessengerModal

const Wrapper = styled.div<{$hidden: boolean}>`
  display: ${({$hidden}) => ($hidden ? 'none' : 'flex')};

  position: fixed;
  top: 0;
  right: 0;

  width: 525px;
  height: 100%;

  z-index: 1055;

  @media (max-width: 800px) {
    width: 350px;
  }
`

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.25);

  z-index: 1055;
`
