import {create} from 'zustand'
import {AvatarModel} from '@api/models'
import {createRelation, deleteRelation, loadRelations, updateRelation} from '@api/relations'

export type Relation = {
  id?: number
  name?: string | null
  name_en?: string | null
  name_ru?: string | null
  status: string
  file?: AvatarModel
  file_id?: number
  base64?: string | null
}

type RelationsStore = {
  loading: boolean
  relations?: Relation[]
  loadRelations: () => void
  updateRelations: (relations: Relation[]) => void
  createRelation: (params: Relation) => void
  updateRelation: (relationId: number, params: Relation) => void
  deleteRelation: (relationId: number) => void
}

export const useRelationsStore = create<RelationsStore>((set) => ({
  loading: false,
  relations: [],
  loadRelations: () => {
    set((state) => ({...state, loading: true}))

    loadRelations()
      .then((response) => {
        set((state) => ({...state, relations: response.data}))
      })
      .finally(() => {
        set((state) => ({...state, loading: false}))
      })
  },
  updateRelations: (relations) => {
    set((state) => ({...state, relations}))
  },
  createRelation: (params: Relation) => {
    set((state) => ({...state, loading: true}))

    createRelation(params)
      .then((response) => {
        set((state) => ({...state, relations: [...(state.relations || []), response.data]}))
      })
      .finally(() => {
        set((state) => ({...state, loading: false}))
      })
  },
  updateRelation: (relationId, params) => {
    set((state) => ({...state, loading: true}))

    updateRelation(relationId, params)
      .then((response) => {
        set((state) => {
          const relations = [...(state.relations || [])]
          const foundIndex = relations.findIndex(({id}) => id === relationId)
          relations[foundIndex] = response.data
          return {...state, relations: relations}
        })
      })
      .finally(() => {
        set((state) => ({...state, loading: false}))
      })
  },
  deleteRelation: (relationId) => {
    set((state) => ({...state, loading: true}))
    deleteRelation(relationId)
      .then(() => {
        set((state) => ({
          ...state,
          relations: state.relations?.filter(({id}) => id !== relationId),
        }))
      })
      .finally(() => {
        set((state) => ({...state, loading: false}))
      })
  },
}))
