import {
  CommentParamTypes,
  createMessagesStoreChatKey,
  useMessagesStore,
} from '@components/molecules/MessengerModal/store'

const useGetChat = (type?: CommentParamTypes, id?: string | number) => {
  const {chats, loadings, loadChat, updateChat, sendMessage, deleteMessage} = useMessagesStore()

  const chatKey = createMessagesStoreChatKey(type, id)

  return {
    chat: chatKey ? chats[chatKey] : undefined,
    loading: chatKey ? loadings[chatKey] : undefined,
    loadChat: () => (type && id ? loadChat(type, id) : undefined),
    updateChat: (chat) => (type && id ? updateChat(type, id, chat) : undefined),
    sendMessage: (text, author, extraOptions) =>
      type && id ? sendMessage(type, id, text, author, extraOptions) : undefined,
    deleteMessage: (messageId: number) =>
      type && id ? deleteMessage(type, id, messageId) : undefined,
  }
}

export default useGetChat
