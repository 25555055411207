import React, {useState} from 'react'
import styled from 'styled-components'
import {useIntl} from 'react-intl'

import {patchFileDescription} from '@api/requests'
import {GalleryImageType} from '@components/molecules/ImagesGalleryModal/ImagesGalleryModal'

type EditPhotoDescriptionProps = {
  image: GalleryImageType
  onSuccess: (data: any) => void
  onCancelClick: () => void
}
const EditPhotoDescription = ({image, onSuccess, onCancelClick}: EditPhotoDescriptionProps) => {
  const intl = useIntl()

  const [description, setDescription] = useState(image.description || '')

  const [saving, setSaving] = useState(false)

  const handleSaveClick = () => {
    console.log('### handleSaveClick', image.id, description)
    setSaving(true)
    patchFileDescription(image.id, description)
      .then((response) => {
        console.log('### patchFileDescription.response', response)
        onSuccess(response.data)
      })
      .catch((error) => console.log('### patchFileDescription.error', error))
      .finally(() => {
        setSaving(false)
      })
  }
  return (
    <Wrapper>
      <div className='row mb-6'>
        <label className='col-lg-12 col-form-label fw-semibold fs-6'>
          {intl.formatMessage({id: 'TREE.ADD.DESCRIPTION'})}
        </label>
        <Input
          type='text'
          autoFocus
          className='form-control form-control-lg'
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </div>

      <Controls className='d-flex justify-content-end ms-2 '>
        <button
          type='reset'
          className='btn btn-sm btn-light '
          disabled={saving}
          onClick={onCancelClick}
        >
          {intl.formatMessage({id: 'ACTIONS.CANCEL'})}
        </button>

        <button
          type='submit'
          className='btn btn-sm btn-primary ms-2 d-flex align-items-center'
          disabled={description === image.description || saving}
          onClick={handleSaveClick}
        >
          {intl.formatMessage({id: 'ACTIONS.SAVE'})}
          {saving ? (
            <span className='indicator-progress' style={{display: 'block'}}>
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          ) : null}
        </button>
      </Controls>
    </Wrapper>
  )
}

export default React.memo(EditPhotoDescription)

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  position: absolute;
  left: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;
`

const Shadow = styled.div`
  display: flex;

  padding: 10px;
  border-radius: 10px;
`

const Input = styled.input`
  min-width: 200px;
`

const Controls = styled.div<{readonly?: boolean}>`
  display: ${({readonly}) => (readonly ? 'none' : 'block')};
`

const Text = styled.text`
  font-size: 20px;
`
