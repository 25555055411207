import {Route, Routes} from 'react-router-dom'
import {Registration} from './components/Registration'
import {ForgotPassword} from './components/ForgotPassword'
import {Login} from './components/Login'
import {ResetPassword} from './components/ResetPassword'
import {AuthLayout} from './AuthLayout'
import {SocialCallback} from './components/SocialCallback'
import {ConfirmEmail} from "@modules/auth/Confirm";

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      <Route path='registration' element={<Registration />} />
      <Route path='confirm' element={<ConfirmEmail />} />
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route path='reset-password' element={<ResetPassword />} />
      <Route path='callback' element={<SocialCallback />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
)

export {AuthPage}
