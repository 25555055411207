import React, {MouseEventHandler} from 'react'

import {useViewArticleDrawerStore} from '@_metronic/partials/layout/viewarticle-drawer/ViewArticleDrawer'

import SimArticleMenu from '@components/molecules/SimArticleMenu'
import ArticleCommentsAndLikes from '@components/atoms/ArticleCommentsAndLikes'

import {Article, PersonModel} from '@api/models'
import {ReactionTargetType} from '@api/requests'
import styled from 'styled-components'

type SimDrawerArticleProps = {
  personId: PersonModel['id']
  article: Article
  upArticle: MouseEventHandler<HTMLSpanElement>
  downArticle: MouseEventHandler<HTMLSpanElement>
  first: boolean
  last: boolean
  maxLength: number
  readonly: boolean
  allowSharing?: boolean
}
const SimDrawerArticle = ({
  personId,
  article,
  first,
  last,
  maxLength,
  upArticle,
  downArticle,
  readonly,
  allowSharing,
}: SimDrawerArticleProps) => {
  console.log('### article', article)
  const {open: showArticle} = useViewArticleDrawerStore()

  const findImage = (doc) => {
    const defImage = 'https://my-engine.ru/modules/users/avatar.png'
    if (!doc || !doc.blocks.length) return defImage
    const image = doc.blocks.find((x) => x.type === 'image')

    if (image) return image.data.file.url
    else return defImage
  }

  return (
    <div className='d-flex align-items-center mb-7'>
      <div
        className='cursor-pointer symbol symbol-50px pe-5'
        onClick={() => showArticle(article, {personId, allowSharing})}
      >
        <img src={findImage(article.doc)} className='object-fit-cover' alt='' />
      </div>

      <OverflowText className='flex-grow-1 flex-shrink-1' style={{width: '100%'}}>
        <OverflowText
          className='text-gray-900 d-block cursor-pointer fw-bold text-hover-primary fs-6'
          onClick={() => showArticle(article, {personId, allowSharing})}
        >
          {article.name}
        </OverflowText>

        <OverflowText className='text-muted d-block fw-bold'>{article.subtitle}</OverflowText>
      </OverflowText>
      {/*<OverflowText className='flex-grow-1 flex-shrink-1' style={{width: '100%'}}>
        {article.name}
      </OverflowText>*/}

      <div className='d-flex flex-shrink-0'>
        <ArticleCommentsAndLikes article={article} />
        <SimArticleMenu
          allowSharing={allowSharing}
          personId={personId}
          article={article}
          first={first}
          last={last}
          readonly={readonly}
          upArticle={upArticle}
          downArticle={downArticle}
        />
      </div>
    </div>
  )
}

export default React.memo(SimDrawerArticle)

const OverflowText = styled.span`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`
