import {create} from 'zustand'
import {AvatarModel} from '@api/models'
import {createCult, deleteCult, loadCults, updateCult} from '@api/looks'

export type Cult = {
  id?: number
  name: string
  description?: string | null
  file_id?: number | null
  file?: AvatarModel
  params?: object
}

type CultsStore = {
  loading: boolean
  cults?: Cult[]
  loadCults: () => void
  createCult: (params: Cult) => void
  updateCult: (cultId: number, params: Cult) => void
  deleteCult: (cultId: number) => void
  updateCults: (cults: Cult[]) => void
}

export const useCultsStore = create<CultsStore>((set) => ({
  loading: false,
  cults: [],
  loadCults: () => {
    set((state) => ({...state, loading: true}))

    loadCults()
      .then((response) => {
        set((state) => {
          const cults = response.data

          const newCultIds = cults.map(({id}) => id)
          return {
            ...state,
            cults: [...(state.cults ?? [])?.filter(({id}) => !newCultIds.includes(id)), ...cults],
          }
        })
      })
      .finally(() => {
        set((state) => ({...state, loading: false}))
      })
  },
  createCult: (params: Cult) => {
    set((state) => ({...state, loading: true}))

    createCult(params)
      .then((response) => {
        console.log('### createCult.response', response)
        set((state) => ({...state, cults: [...(state.cults || []), response.data]}))
      })
      .finally(() => {
        set((state) => ({...state, loading: false}))
      })
  },
  updateCult: (cultId, params) => {
    set((state) => ({...state, loading: true}))

    updateCult(cultId, params)
      .then((response) => {
        set((state) => {
          const cults = [...(state.cults || [])]
          const foundIndex = cults.findIndex(({id}) => id === cultId)
          cults[foundIndex] = response.data
          return {...state, cults: cults}
        })
      })
      .finally(() => {
        set((state) => ({...state, loading: false}))
      })
  },
  deleteCult: (cultId) => {
    set((state) => ({...state, loading: true}))
    deleteCult(cultId)
      .then(() => {
        set((state) => ({...state, cults: state.cults?.filter(({id}) => id !== cultId)}))
      })
      .finally(() => {
        set((state) => ({...state, loading: false}))
      })
  },
  updateCults: (cults) => {
    console.log('### updateCults', cults)

    set((state) => {
      const newCultIds = cults.map(({id}) => id)
      return {
        ...state,
        cults: [...(state.cults ?? [])?.filter(({id}) => !newCultIds.includes(id)), ...cults],
      }
    })
  },
}))
