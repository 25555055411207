import React, {CSSProperties, useState} from 'react'
import styled from 'styled-components'

import {usePersonsStore} from '@store/persons'
import {usePhotosStore} from '@store/photos'
import {useImagesGalleryModalStore} from '@components/molecules/ImagesGalleryModal/ImagesGalleryModal'

import usePersonAvatars from '@hooks/usePersonAvatars'
import useHostBasedUrl from '@hooks/useHostBasedUrl'
import {useAuth} from '@modules/auth'

import Photo from '@components/molecules/CustomGallery/components/Photo'
import EditPhotoDescription from '@components/molecules/CustomGallery/components/EditPhotoDescription'

import {AvatarModel, PersonModel} from '@api/models'

const defaultAvatarStyle: CSSProperties = {
  width: '100%',
  height: 150,
  borderRadius: 12,
  cursor: 'pointer',
  objectFit: 'cover',
}

type SimDrawerAvatarsProps = {readonly: boolean; personId: PersonModel['id']}
const SimDrawerAvatars = ({readonly, personId}: SimDrawerAvatarsProps) => {
  const avatars = usePersonAvatars(personId)

  const {setPhotos, setActivePhoto} = usePhotosStore()
  const {persons, editPerson} = usePersonsStore()
  const foundPerson = persons.find(({id}) => id === personId)

  const {currentUser} = useAuth()
  const handlePhotoDelete = (photoId: number) => {
    console.log('### handlePhotoDelete', personId, photoId, foundPerson?.params.avatars)
    if (foundPerson) {
      const newPersonData = {
        avatar: photoId === foundPerson?.avatar?.id ? null : foundPerson?.avatar,
        params: {
          ...foundPerson?.params,
          avatars: foundPerson?.params.avatars.filter(({id}) => id !== photoId),
        },
      }

      console.log('### newPersonData', newPersonData)
      // setDeletedPhotoIds((state) => [...(state || []), photoId])

      editPerson(personId, newPersonData, {currentUser})
    }
  }

  const {open} = useImagesGalleryModalStore()

  const [editImage, setEditImage] = useState<AvatarModel | null>(null)
  const handleSuccesfullUpdate = (data) => {
    setEditImage(null)

    if (foundPerson) {
      const updatedAvatars = [...foundPerson?.params.avatars]
      const avatarIndex = updatedAvatars.findIndex(({id}) => id === data.id)

      if (avatarIndex !== -1) {
        updatedAvatars[avatarIndex] = {...updatedAvatars[avatarIndex], ...data}
        const newPersonData = {
          params: {
            ...foundPerson?.params,
            avatars: updatedAvatars,
          },
        }

        console.log('### newPersonData', newPersonData)
        // setDeletedPhotoIds((state) => [...(state || []), photoId])

        editPerson(personId, newPersonData, {currentUser})
      }
    }
  }

  return (
    <>
      {!readonly || (readonly && avatars?.length > 0) ? (
        <Wrapper className='row gy-3 mb-5 mt-5' $disabled={!!editImage}>
          {avatars?.map((photo, index) => (
            <div key={index} className='col-md-6'>
              <Photo
                style={defaultAvatarStyle}
                readonly={readonly || foundPerson?.avatar?.id === photo.id}
                photo={photo}
                onDelete={handlePhotoDelete}
                /*onClick={() => {
                  open({images: avatars, startIndex: index})
                }}*/
                onEditClick={readonly ? undefined : () => setEditImage(photo)}
              />
            </div>
          ))}
        </Wrapper>
      ) : null}
      {editImage ? (
        <EditPhotoDescription
          image={editImage}
          onSuccess={handleSuccesfullUpdate}
          onCancelClick={() => setEditImage(null)}
        />
      ) : null}
    </>
  )
}

export default React.memo(SimDrawerAvatars)

const Wrapper = styled.div<{$disabled: boolean}>`
  opacity: ${({$disabled}) => ($disabled ? 0.1 : 1)};
`
