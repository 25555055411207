import React from 'react'
import {useIntl} from 'react-intl'
import {create} from 'zustand'
import styled from 'styled-components'

import {RelationDrawerContent} from './RelationDrawerContent'

import {KTIcon} from '../../../helpers'
import {Cult} from '@pages/CultsPage/store'
import {Relation} from '@pages/RelationsPage/store'
import {useAddEditRelationDrawerStore} from '@_metronic/partials/layout/addrelation-drawer/AddEditRelationDrawer'

type RelationDrawerStore = {
  opened: boolean
  relationId?: Relation['id'] | null
  show: (cultId: Cult['id']) => void
  hide: () => void
}

export const useRelationDrawerStore = create<RelationDrawerStore>((set) => ({
  opened: false,
  cultId: null,
  show: (relationId) => set((state) => ({...state, opened: true, relationId})),
  hide: () => set((state) => ({...state, opened: false, relationId: null})),
}))

type CultDrawerProps = {
  readonly?: boolean
  refToken?: string
}

const RelationDrawer = ({readonly = false, refToken}: CultDrawerProps) => {
  const intl = useIntl()
  const {opened, relationId, hide} = useRelationDrawerStore()

  const {open: showEditRelationDrawer} = useAddEditRelationDrawerStore()

  const openEdit = () => {
    hide()
    showEditRelationDrawer(relationId!)
  }

  return (
    <>
      {opened ? <Overlay onClick={hide} /> : null}
      <Wrapper className='bg-body' $hidden={!opened}>
        {/* begin::Card */}
        <div className='card shadow-none rounded-0 w-100'>
          {/* begin::Header */}
          <div className='card-header' id='kt_help_header'>
            <h5 className='card-title fw-bold'>
              {intl.formatMessage({id: 'RELATION.CARD.TITLE'})}
            </h5>

            <div className='card-toolbar'>
              <button
                type='button'
                className='btn btn-sm btn-icon explore-btn-dismiss me-n5'
                onClick={() => hide()}
              >
                <KTIcon iconName='cross' className='fs-2' />
              </button>
            </div>
          </div>
          {/* end::Header */}

          {/* begin::Body */}
          <div className={'card-body hover-scroll-overlay-y h-400px pt-0'}>
            <RelationDrawerContent relationId={relationId} readonly={readonly} />
          </div>
          {/* end::Body */}

          {/* begin::Footer */}
          {!readonly ? (
            <div className='card-footer py-5 text-center' id='kt_activities_footer'>
              <div className='d-flex justify-content-end'>
                <button
                  className='btn btn-secondary d-flex justify-content-end me-3'
                  onClick={hide}
                >
                  {intl.formatMessage({id: 'TREE.ADD.CLOSE'})}
                </button>
                <button className='btn btn-success d-flex justify-content-end' onClick={openEdit}>
                  {intl.formatMessage({id: 'EDIT'})}
                </button>
              </div>
            </div>
          ) : null}
          {/* end::Footer */}
        </div>
        {/* end::Card */}
      </Wrapper>
    </>
  )
}

export {RelationDrawer}

const Wrapper = styled.div<{$hidden: boolean}>`
  display: ${({$hidden}) => ($hidden ? 'none' : 'flex')};

  position: fixed;
  top: 0;
  right: 0;

  width: 525px;
  height: 100%;

  z-index: 106;

  @media (max-width: 800px) {
    width: 350px;
  }
`

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.25);

  z-index: 105;
`
