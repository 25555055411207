/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useMemo, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import * as linkify from 'linkifyjs'
import linkifyHtml from 'linkify-html'

import {useAuth} from '@modules/auth'
import useTreeAvatars from '@hooks/useTreeAvatars'
import useHostBasedUrl from '@hooks/useHostBasedUrl'

import {useTreesStore} from '@store/trees'
import {usePhotosStore} from '@store/photos'
import {useSubscriptionModalStore} from '@components/molecules/SubscriptionModal/SubscriptionModal'

import {getPhotos, uploadImage} from '@api/requests'

import CustomGallery from '@components/molecules/CustomGallery/CustomGallery'

import {GALLERY_IMAGE_TARGET_SIZES} from '@constants/imageUploading'
import styled from 'styled-components'

const ViewTreeDrawerContent = ({treeId, readonly = false}) => {
  const intl = useIntl()
  const butOpenEditorRef = useRef<any>()
  const {selectPhoto, setActivePhoto, setPhotos: setGalleryPhotos} = usePhotosStore()
  const {trees} = useTreesStore()
  const tree = trees[treeId]
  const [photos, setPhotos] = useState([])

  const {currentUser} = useAuth()
  const {show: showSubscriptionModal} = useSubscriptionModalStore()
  const imageRef = useRef<HTMLInputElement | null>(null)
  const clickToInput = (event) => {
    if (!currentUser?.is_premium) {
      showSubscriptionModal()
      event.preventDefault()
      event.stopPropagation()

      return
    }

    imageRef.current?.click()
  }
  useEffect(() => {
    // @ts-ignore
    getPhotos(`tree_gallery_${treeId}`).then((r) => {
      // @ts-ignore
      setPhotos(r.data)
    })
  }, [treeId])

  const callBackForCrop = (file) => {
    let reader = new FileReader()
    reader.onload = function (e) {
      if (e && e.target && e.target.result) {
        uploadImage(file, `tree_gallery_${treeId}`).then((r) => {
          let ph = photos ? [...photos] : []
          // @ts-ignore
          ph.push(r.data)
          setPhotos(ph)
        })
      }
    }
    reader.readAsDataURL(file)
  }

  function handleFiles(event) {
    if (!event.currentTarget.files || !event.currentTarget.files.length) return
    let file = event.currentTarget.files[0]
    selectPhoto(file, callBackForCrop, undefined, GALLERY_IMAGE_TARGET_SIZES)

    event.currentTarget.value = ''

    if (butOpenEditorRef && butOpenEditorRef.current) butOpenEditorRef.current.click()
  }

  const treeAvatarUrl = useHostBasedUrl(tree?.avatar?.url)
  const avatars = useTreeAvatars(treeId)

  const handleImageUpdate = (data) => {
    console.log('### handleImageUpdate', data)

    setPhotos((oldPhotos) => {
      const newPhotos = [...oldPhotos]
      const foundIndex = newPhotos.findIndex(({id}) => id === data.id)
      console.log('### foundIndex', foundIndex)
      if (foundIndex !== -1) {
        // @ts-ignore
        newPhotos[foundIndex] = {...newPhotos[foundIndex], ...data}
      }

      return newPhotos
    })
  }

  const linkedDescription = useMemo(
    () =>
      tree?.description
        ? linkifyHtml(tree.description, {defaultProtocol: 'https', target: {url: '_blank'}})
        : null,
    [tree]
  )

  return (
    <div style={{overflowX: 'hidden'}} className='card-body hover-scroll-overlay-y h-400px pt-5'>
      <button
        ref={butOpenEditorRef}
        style={{pointerEvents: 'none', opacity: 0, position: 'absolute'}}
        data-bs-toggle='modal'
        data-bs-target='#kt_modal_edit_photo'
      />

      <div className='d-flex flex-column text-center mb-9 px-9'>
        <div
          className='symbol symbol-150px mb-4'
          /*onClick={() => {
            if (avatars.length > 0) {
              // @ts-ignore
              setGalleryPhotos(avatars)
              const currentAvatarIndex = avatars.findIndex(({url}) => url === treeAvatarUrl)
              setActivePhoto(currentAvatarIndex >= 0 ? currentAvatarIndex : 0)
            }
          }}*/
        >
          <img
            src={treeAvatarUrl ? treeAvatarUrl : 'https://my-engine.ru/modules/users/avatar.png'}
            className=''
            alt=''
          />
        </div>

        <div className='text-center'>
          <span className='text-gray-800 fw-bold text-hover-primary fs-1'>{tree?.name}</span>

          <span className='text-muted d-block fw-semibold'>
            {intl.formatMessage({id: 'TREE.DINASTY.TITLE'})}
          </span>
        </div>
      </div>

      {/*{avatars?.length > 0 ? (*/}
      {/*  <CustomGallery currentPhoto={treeAvatarUrl} readonly={readonly} photos={avatars} />*/}
      {/*) : null}*/}

      <div className='card border-0 shadow-none rounded-0 w-100'>
        <div className='card-header p-0 mb-4'>
          {photos?.length > 0 || !readonly ? (
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold text-gray-900'>
                {intl.formatMessage({id: 'GALLERY.TITLE'})}
              </span>
            </h3>
          ) : null}
          {!readonly ? (
            <div className='card-toolbar'>
              <span onClick={clickToInput} className='btn btn-sm btn-light'>
                {intl.formatMessage({id: 'ADD.PHOTO'})}
              </span>
              <input
                ref={imageRef}
                style={{position: 'fixed', width: 0, pointerEvents: 'none', opacity: 0}}
                type='file'
                name='avatar'
                accept='.png, .jpg, .jpeg'
                onChange={handleFiles}
              />
            </div>
          ) : null}
        </div>
        {photos?.length > 0 ? (
          <CustomGallery readonly={readonly} photos={photos} onImageUpdate={handleImageUpdate} />
        ) : null}
      </div>

      {(tree?.params?.fields && tree?.params?.fields?.length > 0) || tree?.description ? (
        <div className='card border-0 shadow-none rounded-0 w-100'>
          <div className='card-header p-0 mb-4'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold text-gray-900'>
                {intl.formatMessage({id: 'INFORMATION'})}
              </span>
            </h3>
          </div>
          {tree?.params?.fields?.map((x, k) => (
            <div className='row mb-7' key={k}>
              <div className='col-lg-4 fw-semibold text-muted fs-6'>{x[0]}</div>
              <div className='col-lg-8 fw-semibold text-gray-800 fs-6'>{x[1]}</div>
            </div>
          ))}

          {linkedDescription ? (
            <Description
              className='lead ql-editor'
              dangerouslySetInnerHTML={{
                __html: linkedDescription,
              }}
            />
          ) : null}
        </div>
      ) : null}
    </div>
  )
}
// renderToStaticMarkup()

export {ViewTreeDrawerContent}

const Description = styled.div`
  & blockquote {
    border-left: 4px solid #ccc;
    margin-bottom: 5px;
    margin-top: 5px;
    padding-left: 16px;
  }
`
