/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {create} from 'zustand'
import styled from 'styled-components'
import '@pqina/pintura/pintura.css'

import useShareTreeLink from '@hooks/useShareTreeLink'

import {Article, PersonModel} from '@api/models'
import {ReactionTargetType} from '@api/requests'

import ArticleCommentsAndLikes from '@components/atoms/ArticleCommentsAndLikes'
import {CommentParamTypes, useMessengerModalStore} from '@components/molecules/MessengerModal/store'
import Messenger from '@components/molecules/MessengerModal/components/Messenger'

type Payload = {
  personId: PersonModel['id']
  allowSharing?: boolean
}

type ViewArticleDrawerStore = {
  opened: boolean
  article: Article | null
  payload?: Payload | null
  open: (article: Article, payload?: Payload) => void
  close: () => void
}

export const useViewArticleDrawerStore = create<ViewArticleDrawerStore>((set) => ({
  opened: false,
  article: null,
  payload: null,
  open: (article, payload) => set((state) => ({...state, opened: true, article, payload})),
  close: () => set((state) => ({...state, opened: false, article: null, payload: null})),
}))

type ViewArticleDrawerProps = {
  refToken?: string
}

const ViewArticleDrawer = ({refToken}: ViewArticleDrawerProps) => {
  const intl = useIntl()

  const {opened, article, payload, close} = useViewArticleDrawerStore()

  const [articleContent, setArticleContent] = useState<String>()

  useEffect(() => {
    setArticleContent(article?.html)
  }, [article])

  const shareLinkPerson = useShareTreeLink(
    {personId: payload?.personId, articleId: article?._id, refToken},
    true
  )
  const {show} = useMessengerModalStore()
  const shareLinkDefault = useShareTreeLink({personId: payload?.personId, refToken}, true)
  useEffect(() => {
    if (opened && article) {
      show({type: CommentParamTypes.article, article}, (count) => {}, true)
    }

    if (opened && payload?.allowSharing) {
      window.history.pushState(null, '', shareLinkPerson)
      return () => {
        window.history.pushState(null, '', shareLinkDefault)
      }
    }
  }, [opened])

  if (!opened) return null

  return (
    <Wrapper className='modal bg-black' tabIndex={-1}>
      <div className='modal-dialog h-100 modal-fullscreen'>
        <div className='modal-content shadow-none'>
          <div className='modal-header'>
            <h3 className='modal-title flex-fill'>
              {intl.formatMessage({id: 'SIM.VIEW.TAB_ARTICLES'})}
            </h3>

            {article ? <ArticleCommentsAndLikes article={article} /> : null}
            <button type='button' className='btn btn-light me-2' onClick={close}>
              {intl.formatMessage({id: 'ACTIONS.CANCEL'})}
            </button>
          </div>

          <div className='modal-body fs-2'>
            <div className='container d-flex flex-column' style={{maxWidth: '800px'}}>
              {articleContent && typeof articleContent === 'string' ? (
                <>
                  <div
                    className='w-100 mb-lg-5'
                    dangerouslySetInnerHTML={{__html: articleContent}}
                  />
                  <Divider />
                  {intl.formatMessage({id: 'COMMENTS'})}
                  <Messenger visible refToken={refToken} embeded />
                </>
              ) : (
                <span>Статья пустая</span>
              )}
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export {ViewArticleDrawer}

const Wrapper = styled.div`
  display: flex;

  position: fixed;
  top: 0;
  right: 0;

  width: 100%;
  height: 100%;
`

const Divider = styled.div`
  margin-top: 100px !important;
  margin-bottom: 30px !important;
  width: 100%;
  height: 1px;

  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
`
