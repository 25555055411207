import React, {useEffect, useMemo, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import styled from 'styled-components'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

import useHostBasedUrl from '@hooks/useHostBasedUrl'

import {Cult, useCultsStore} from '@pages/CultsPage/store'
import {Relation, useRelationsStore} from '@pages/RelationsPage/store'
import {relationNamesMap} from '@components/molecules/PersonsModal'

const RelationDrawerContent = ({relationId, readonly = false}) => {
  const intl = useIntl()
  const butOpenEditorRef = useRef<any>()

  const {relations} = useRelationsStore()

  const [loading, setLoading] = useState(false)

  const [relation, setRelation] = useState<Relation | null>(null)

  useEffect(() => {
    const foundRelation = relations?.find(({id}) => id === relationId)

    if (foundRelation) {
      setRelation(foundRelation)
    }
  }, [relationId, relations])

  useEffect(() => {
    if (!relationId) {
      setRelation(null)
    }
  }, [relationId])

  const avatarUrl = useHostBasedUrl(relation?.file?.url)
  const relationName = useMemo(() => {
    if (!relation) return null
    return relationNamesMap[relation.status]
      ? intl.formatMessage({id: relation.status})
      : relation.name_en || relation.name_ru || relation.status
  }, [relation])

  return (
    <div className='pt-5' style={{overflowX: 'hidden'}}>
      <button
        ref={butOpenEditorRef}
        style={{pointerEvents: 'none', opacity: 0, position: 'absolute'}}
        data-bs-toggle='modal'
        data-bs-target='#kt_modal_edit_photo'
      />
      <div className='d-flex flex-column text-center mb-9 px-9'>
        <div className='symbol symbol-150px mb-4'>
          {/* @ts-ignore*/}
          {avatarUrl ? (
            <img src={avatarUrl} className='' alt='' style={{objectFit: 'cover'}} />
          ) : (
            <img
              src='https://my-engine.ru/modules/users/avatar.png'
              alt=''
              style={{objectFit: 'cover'}}
            />
          )}
          {loading ? (
            <div className='d-flex flex-fill justify-content-center'>
              <span className='indicator-progress mt-5' style={{display: 'block'}}>
                {intl.formatMessage({id: 'ACTIONS.WAIT'})}{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            </div>
          ) : null}
        </div>

        <div className='text-center'>
          <span className='text-gray-800 fw-bold text-hover-primary fs-1'>{relationName}</span>
        </div>
      </div>
    </div>
  )
}

export {RelationDrawerContent}
