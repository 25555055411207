/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'

import {uploadImage} from '@api/requests'
import useOnScreen from '@hooks/useOnScreen'

import {usePhotosStore} from '@store/photos'

import ReactQuillEditor from '@components/molecules/ReactQuillEditor'
import CustomFields from '@components/molecules/CustomFields'

import {AVATAR_IMAGE_TARGET_SIZES} from '@constants/imageUploading'

const AddEditCultDrawerContent = ({initialValues, onAvatarChange, onValuesChange}) => {
  console.log('### initialValues', initialValues)
  const intl = useIntl()
  const butOpenEditorRef = useRef<any>()
  const {selectPhoto} = usePhotosStore()
  const [name, setName] = useState(initialValues?.name ?? '')
  const [description, setDescription] = useState(initialValues?.description ?? '')

  const [avatar, setAvatar] = useState(initialValues?.file?.url)
  const [avatar_id, setAvatarId] = useState<number | null>(initialValues?.file?.id)
  const [customsFields, setCustomFields] = useState<any>(initialValues?.file?.params || [])

  const addSimDrawerRef = useRef(null)
  const visible = useOnScreen(addSimDrawerRef)

  useEffect(() => {
    if (!visible) {
      setName(initialValues?.name ?? '')
      setDescription(initialValues?.description ?? '')
      setAvatar(initialValues?.file?.url)
      setAvatarId(initialValues?.file?.id)
      setCustomFields(initialValues?.params?.fields || [])
    }
  }, [visible, initialValues])

  useEffect(() => {
    onValuesChange({
      name,
      description,
      avatar_id,
      params: {fields: customsFields ? customsFields : []},
    })
  }, [name, customsFields, avatar_id, description])
  const callBackForCrop = (file) => {
    let reader = new FileReader()
    reader.onload = function (e) {
      if (e?.target?.result) {
        uploadImage(file, 1).then((r) => {
          setAvatarId(r.data.id)
          // @ts-ignore
          onAvatarChange(r.data)
        })
      }
      // @ts-ignore
      setAvatar(e.target.result)
    }
    reader.readAsDataURL(file)
  }
  function handleFiles(event) {
    let file = event.currentTarget.files[0]
    selectPhoto(file, callBackForCrop, 1, AVATAR_IMAGE_TARGET_SIZES, false, true)

    event.currentTarget.value = ''

    if (butOpenEditorRef?.current)
      //@ts-ignore
      butOpenEditorRef.current.click()
  }

  return (
    <div
      className='card-body hover-scroll-overlay-y h-400px pt-5'
      id='kt_help_body'
      ref={addSimDrawerRef}
    >
      {/* @ts-ignore */}
      <button
        ref={butOpenEditorRef}
        style={{pointerEvents: 'none', opacity: 0, position: 'absolute'}}
        data-bs-toggle='modal'
        data-bs-target='#kt_modal_edit_photo'
      />
      <div className='row mb-6'>
        <label className='col-lg-12 col-form-label fw-semibold fs-6'>
          {intl.formatMessage({id: 'TREE.ADD.PHOTO'})}
        </label>
        <div className='col-lg-12'>
          <div className='image-input image-input-outline' data-kt-image-input='true'>
            <div
              style={{
                backgroundImage: avatar
                  ? `url('${avatar}')`
                  : 'url("https://my-engine.ru/modules/users/avatar.png")',
              }}
              className='image-input-wrapper w-125px h-125px'
            />
            <label
              className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
              data-kt-image-input-action='change'
              data-bs-toggle='tooltip'
              title='Change avatar'
            >
              <i className='ki-duotone ki-pencil fs-7'>
                <span className='path1' />
                <span className='path2' />
              </i>
              <input onChange={handleFiles} type='file' name='avatar' accept='.png, .jpg, .jpeg' />
              <input type='hidden' name='avatar_remove' />
            </label>
            <span
              className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
              data-kt-image-input-action='cancel'
              data-bs-toggle='tooltip'
              title='Cancel avatar'
            >
              <i className='ki-duotone ki-cross fs-2'>
                <span className='path1' />
                <span className='path2' />
              </i>
            </span>
          </div>
          <div className='form-text'>{intl.formatMessage({id: 'TREE.ADD.PHOTO.FORMAT'})}</div>
        </div>
      </div>
      <div className='mb-10'>
        <label className='required form-label'>{intl.formatMessage({id: 'ADD.FIELD.NAME'})}</label>
        <input
          type='text'
          value={name}
          onChange={(e) => setName(e.currentTarget.value)}
          className='form-control'
          placeholder=''
        />
      </div>

      <CustomFields values={customsFields} onChange={setCustomFields} />

      <div className='mb-10'>
        <label className=' form-label'>{intl.formatMessage({id: 'TREE.ADD.DESCRIPTION'})}</label>
        <ReactQuillEditor initValue={description} setValue={setDescription} />
      </div>
      {/* end::Content */}
    </div>
  )
}

export {AddEditCultDrawerContent}
