import {create} from 'zustand'

import {Article} from '@api/models'

import {OutputData} from '@editorjs/editorjs'

type ArticlesStore = {
  activeArticleId: number | null
  selectedArticle: null | Article
  activePersonArticles: [] | Article[]
  selectArticle: (article: Article | null) => void
  setSortForArticles: (arrOfArticles: {_id: string; sort: number | undefined}[]) => void
  editArticle: (_id: string, doc: OutputData, name: string, subtitle: string, html: String) => void
  removeArticle: (id: string) => void
  addArticle: (Article: Article) => void
  setActivePersonArticles: (Articles: Article[]) => void
}

export const useArticleStore = create<ArticlesStore>((set, get) => ({
  activeArticleId: null,
  selectedArticle: null,
  activePersonArticles: [],

  setSortForArticles: (arrOfArticles) => {
    set((state) => {
      let articles = state.activePersonArticles
      arrOfArticles.map((sortArticle) => {
        let index = articles.findIndex((article) => article._id === sortArticle._id)
        if (index !== -1) {
          articles[index].sort = sortArticle.sort
        }
      })

      return {
        ...state,
        activePersonArticles: articles,
      }
    })
  },
  selectArticle: (article) => {
    set((state) => {
      return {
        ...state,
        selectedArticle: article,
      }
    })
  },
  editArticle: (_id, doc, name, subtitle, html) => {
    console.log('### editArticle', _id, doc, name, subtitle, html)
    set((state) => {
      let arts = [...state.activePersonArticles]
      let index = arts.findIndex((x) => x._id === _id)
      if (index >= 0) {
        arts[index].doc = doc
        arts[index].name = name
        arts[index].subtitle = subtitle
        arts[index].html = html
      }
      return {
        ...state,
        activePersonArticles: arts,
      }
    })
  },
  removeArticle: (_id: string) => {
    set((state) => {
      let arts = [...state.activePersonArticles]
      let index = arts.findIndex((x) => x._id === _id)
      if (index >= 0) arts.splice(index, 1)
      return {
        ...state,
        activePersonArticles: arts,
      }
    })
  },
  addArticle: (article: Article) => {
    set((state) => ({
      ...state,
      activePersonArticles: [article, ...state.activePersonArticles],
    }))
  },
  setActivePersonArticles: (articles: Article[]) => {
    set((state) => ({
      ...state,
      activePersonArticles: articles,
    }))
  },
}))
