import {create} from 'zustand'
import {getMineReactions, ReactionTargetType, ReactionTypes} from '@api/requests'

type ReactionsStore = {
  data: {
    [key in ReactionTypes]?: {
      loading: boolean
      reactions: {id: number | string; type: ReactionTargetType}[]
    }
  }
  refreshReactions: (reactionType: ReactionTypes) => void
}
const useReactionsStore = create<ReactionsStore>((set) => ({
  data: {},
  refreshReactions: (reactionType) => {
    set((state) => ({
      ...state,
      data: {...state.data, [reactionType]: {...state[reactionType], loading: true}},
    }))

    getMineReactions(reactionType)
      .then((response) => {
        console.log('### getMineReactions.response', response)

        set((state) => ({
          ...state,
          data: {
            ...state.data,
            [reactionType]: {...state[reactionType], reactions: response.data, loading: false},
          },
        }))
      })
      .catch(() => {
        set((state) => ({
          ...state,
          data: {...state.data, [reactionType]: {...state[reactionType], loading: false}},
        }))
      })
    // set((state) => ({...state, reactions}))
  },
}))

export default useReactionsStore
