import {Article, TreePublishType} from '@api/models'
import {create} from 'zustand'
import {
  MessageAuthorType,
  MessagesInfo,
  MessageType,
} from '@components/molecules/MessengerModal/types'
import {createComment, deleteComment, getComments, ReactionTargetType} from '@api/requests'

export enum CommentParamTypes {
  tree = 'tree',
  article = 'article',
}

type TreePayload = {
  type: CommentParamTypes.tree
  tree: TreePublishType
}

type ArticlePayload = {
  type: CommentParamTypes.article
  article: Article
}

export type MessengerPayloadType = TreePayload | ArticlePayload | null

type MessengerModalStore = {
  opened: boolean
  payload: MessengerPayloadType
  sentCallback?: (count: number) => void
  show: (
    payload: TreePayload | ArticlePayload,
    sentCallback?: (count: number) => void,
    withoutShowing?: boolean
  ) => void
  hide: () => void
}
export const useMessengerModalStore = create<MessengerModalStore>((set) => ({
  opened: false,
  payload: null,
  show: (payload, sentCallback, withoutShowing) =>
    set((state) => ({...state, opened: !withoutShowing, payload, sentCallback})),
  hide: () => set((state) => ({...state, opened: false, payload: null, sentCallback: undefined})),
}))

export const createMessagesStoreChatKey = (type?: CommentParamTypes, id?: string | number) =>
  type && id ? `${type}_${id}` : null
type MessagesStore = {
  loadings: {[key: string]: boolean}
  chats: {[key: string]: MessagesInfo}
  updateChat: (type: CommentParamTypes, id: string | number, chat: MessagesInfo) => void
  sendMessage: (
    type: CommentParamTypes,
    id: string | number,
    text: string,
    author: MessageAuthorType,
    extraOptions?: {citationId: number; unselectCitation?: () => void; chatId?: number}
  ) => void
  deleteMessage: (
    type: CommentParamTypes,
    id: string | number,
    messageId: MessageType['id']
  ) => void
  loadChat: (type: CommentParamTypes, id: string | number) => void
}
export const useMessagesStore = create<MessagesStore>((set) => ({
  chats: {},
  loadings: {},
  updateChat: (type, id, chat) => {
    const chatKey = createMessagesStoreChatKey(type, id)

    if (!chatKey) return

    set((state) => ({
      ...state,
      chats: {...state.chats, [chatKey]: chat},
    }))
  },
  sendMessage: (type, id, text, author, extraOptions) => {
    let params

    if (type === CommentParamTypes.tree && (extraOptions?.chatId || id)) {
      params = {id: extraOptions?.chatId || id, type: ReactionTargetType.tree}
    } else if (type === CommentParamTypes.article && id) {
      params = {id: id, type: ReactionTargetType.article}
    }

    if (params) {
      const {citationId, unselectCitation} = extraOptions || {}

      createComment(params, text, citationId)
        .then((response) => {
          const chatKey = createMessagesStoreChatKey(type, id)
          if (!chatKey) return

          set((state) => {
            const chat = state.chats[chatKey]

            if (!chat) return state

            const {authors, comments} = chat

            const updatedComments = [...(comments || []), response.data as MessageType]

            return {
              ...state,
              chats: {
                ...state.chats,
                [chatKey]: {
                  authors: authors[author.id] ? authors : {...authors, [author.id]: author},
                  comments: updatedComments,
                },
              },
            }
          })

          unselectCitation?.()
        })
        .catch((error) => {
          console.log('### createComment.error', error)
        })
    }
  },
  deleteMessage: (type, id, messageId) => {
    deleteComment(messageId).then((response) => {
      const chatKey = createMessagesStoreChatKey(type, id)
      if (!chatKey) return

      set((state) => {
        const chat = state.chats[chatKey]

        const {comments} = chat

        return {
          ...state,
          chats: {
            ...state.chats,
            [chatKey]: {
              ...state.chats[chatKey],
              comments: comments.filter((comment) => comment.id !== messageId) || [],
            },
          },
        }
      })
    })
  },
  loadChat: (type, id) => {
    let params

    if (type === CommentParamTypes.tree && id) {
      params = {token: id, type: ReactionTargetType.tree}
    } else if (type === CommentParamTypes.article && id) {
      params = {id: id, type: ReactionTargetType.article}
    }

    if (params) {
      const chatKey = createMessagesStoreChatKey(type, id)
      if (!chatKey) return

      set((state) => {
        return {...state, loadings: {...state.loadings, [chatKey]: true}}
      })

      // @ts-ignore
      getComments(params)
        .then((response) => {
          set((state) => {
            return {...state, chats: {...state.chats, [chatKey]: response.data}}
          })
        })
        .catch((error) => {
          console.log('### getComments.error', error)
        })
        .finally(() => {
          set((state) => {
            return {...state, loadings: {...state.loadings, [chatKey]: false}}
          })
        })
    }
  },
}))
