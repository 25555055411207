import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {create} from 'zustand'
import styled from 'styled-components'

import {KTIcon} from '../../../helpers'
import {useAuth, UserModel} from '@modules/auth'

import {UserProfileDrawerContent} from './components/UserProfileDrawerContent'
import {updateProfile} from '@api/requests'

const MySwal = withReactContent(Swal)

type UserProfileDrawerStore = {
  opened: boolean
  open: () => void
  close: () => void
}

export const useUserProfileDrawerStore = create<UserProfileDrawerStore>((set) => ({
  opened: false,
  open: () => set((state) => ({...state, opened: true})),
  close: () => set((state) => ({...state, opened: false})),
}))

export type UserProfileValues = {
  avatar?: {id: number; url: string} | null
  first_name?: string
  description?: string
  socialNetworks?: any[]
  password?: string
  passwordConfirm?: string
}

const UserProfileDrawer = () => {
  const intl = useIntl()
  const {currentUser, setCurrentUser, logout} = useAuth()

  const {opened, close} = useUserProfileDrawerStore()

  const [values, setValues] = useState<UserProfileValues>({
    avatar: currentUser?.avatar,
    first_name: currentUser?.first_name,
    description: currentUser?.description,
    socialNetworks: currentUser?.params?.socialNetworks,
  })

  useEffect(() => {
    setValues((state) => ({
      ...state,
      avatar: currentUser?.avatar,
      first_name: currentUser?.first_name,
      description: currentUser?.description,
      socialNetworks: currentUser?.params?.socialNetworks,
    }))
  }, [currentUser])

  const handleValuesChange = (data) => {
    setValues(data)
  }

  const handleSavePress = () => {
    const data: {
      avatar_id?: number
      first_name?: string
      description?: string
      params?: UserModel['params']
      password?: string
    } = {}

    if (values.avatar?.id && currentUser?.avatar?.id !== values.avatar?.id) {
      data.avatar_id = values.avatar?.id
    }

    if (!values.first_name) {
      MySwal.fire({
        position: 'center',
        icon: 'error',
        title: ' Имя не может быть пустым',
        showConfirmButton: false,
        timer: 1500,
        heightAuto: false,
      })
      return
    }

    if (values.password) {
      if (values.password !== values.passwordConfirm) {
        MySwal.fire({
          position: 'center',
          icon: 'error',
          title: 'Пароли не совпадают',
          showConfirmButton: false,
          timer: 1500,
          heightAuto: false,
        })
        return
      }

      if (values.password.length < 8) {
        MySwal.fire({
          position: 'center',
          icon: 'error',
          title: 'Пароли слишком короткий',
          showConfirmButton: false,
          timer: 1500,
          heightAuto: false,
        })
        return
      }

      data.password = values.password
    }

    if (values.first_name && currentUser?.first_name !== values.first_name) {
      data.first_name = values.first_name
    }

    if (values.description && currentUser?.description !== values.description) {
      data.description = values.description
    }

    if (
      (!currentUser?.params && values.socialNetworks) ||
      JSON.stringify(currentUser?.params?.socialNetworks) !== JSON.stringify(values.socialNetworks)
    ) {
      data.params = {...currentUser?.params, socialNetworks: values.socialNetworks}
    }

    if (Object.keys(data).length) {
      updateProfile(data).then((response) => {
        MySwal.fire({
          position: 'center',
          icon: 'success',
          title: intl.formatMessage({id: 'MSG.PROFILE.UPDATED'}),
          showConfirmButton: false,
          timer: 1500,
          heightAuto: false,
        }).then(() => {
          setCurrentUser(response.data)
          close()

          if (data.password) {
            setValues((state) => ({
              ...state,
              password: '',
              passwordConfirm: '',
            }))
            logout()
          }
        })
      })
    } else {
      close()
    }
  }

  return (
    <>
      {opened ? <Overlay onClick={close} /> : null}
      <Wrapper className='bg-body' $hidden={!opened}>
        {/* begin::Card */}
        <div className='card shadow-none rounded-0 w-100'>
          {/* begin::Header */}
          <div className='card-header' id='kt_help_header'>
            <h5 className='card-title fw-bold'>{intl.formatMessage({id: 'USER.EDIT_PROFILE'})}</h5>

            <div className='card-toolbar'>
              <button
                type='button'
                className='btn btn-sm btn-icon explore-btn-dismiss me-n5'
                onClick={close}
              >
                <KTIcon iconName='cross' className='fs-2' />
              </button>
            </div>
          </div>
          {/* end::Header */}

          {/* begin::Body */}
          <UserProfileDrawerContent close={close} values={values} onValuesChange={handleValuesChange} />
          {/* end::Body */}

          {/* begin::Footer */}
          <div className='card-footer py-5 text-center' id='kt_activities_footer'>
            <div className='d-flex justify-content-end'>
              <button className='btn btn-secondary d-flex justify-content-end me-3' onClick={close}>
                {intl.formatMessage({id: 'ACTIONS.CLOSE'})}
              </button>
              <button
                className='btn btn-success d-flex justify-content-end'
                onClick={handleSavePress}
              >
                {intl.formatMessage({id: 'ACTIONS.SAVE'})}
              </button>
            </div>
          </div>
          {/* end::Footer */}
        </div>
        {/* end::Card */}
      </Wrapper>
    </>
  )
}

export {UserProfileDrawer}

const Wrapper = styled.div<{$hidden: boolean}>`
  display: ${({$hidden}) => ($hidden ? 'none' : 'flex')};

  position: fixed;
  top: 0;
  right: 0;

  width: 525px;
  height: 100%;

  z-index: 106;

  @media (max-width: 800px) {
    width: 350px;
  }
`

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.25);

  z-index: 105;
`
