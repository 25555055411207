import React, {useMemo} from 'react'
import styled from 'styled-components/'
import {useIntl} from 'react-intl'

import {PersonModel, TreeModel} from '@api/models'

import useTreeStyles from '@hooks/useTreeStyles'

import {NODE_AVATAR_SIZE, NODE_HEIGHT, NODE_WIDTH} from '@components/organisms/Tree/constants'

const SIZE = NODE_WIDTH * 1.5

type NodeInfoProps = {
  style?: any
  treeId: TreeModel['id']
  person: PersonModel
  leftOffset?: number
}
const NodeInfo = ({style, treeId, person, leftOffset}: NodeInfoProps) => {
  const intl = useIntl()

  const {
    fullNameColor,
    diedStatusColor,
    firstNameDisplayingEnabled: firstNameDisplayingEnabledString,
    lastNameDisplayingEnabled: lastNameDisplayingEnabledString,
    diedStatusDisplayingEnabled: diedStatusDisplayingEnabledString,
  } = useTreeStyles(treeId)

  const firstNameDisplayingEnabled = firstNameDisplayingEnabledString === 'true'
  const lastNameDisplayingEnabled = lastNameDisplayingEnabledString === 'true'
  const diedStatusDisplayingEnabled = diedStatusDisplayingEnabledString === 'true'

  const mergedStyle = useMemo(() => {
    return {
      ...style,
      height: 'auto',
      width: SIZE,
      left: (style?.left || 0) + leftOffset,
      top: NODE_HEIGHT - 5,
    }
  }, [style])

  return (
    <Wrapper style={mergedStyle}>
      {firstNameDisplayingEnabled ? (
        <Name $color={fullNameColor as string}>{person.first_name}</Name>
      ) : null}
      {lastNameDisplayingEnabled ? (
        <Name $color={fullNameColor as string}>{person.last_name}</Name>
      ) : null}

      {diedStatusDisplayingEnabled ? (
        <State $color={diedStatusColor as string}>
          {intl.formatMessage({id: person.is_alive === 0 ? 'PERSON.DEAD' : 'PERSON.ALIVE'})}
        </State>
      ) : null}
    </Wrapper>
  )
}

export default React.memo(NodeInfo)

const Wrapper = styled.div`
  position: absolute;

  z-index: -1;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
`

const Name = styled.span<{$color: string}>`
  color: ${({$color}) => $color};
  font-size: 11px;

  width: 100%;
  text-align: center;
  white-space: nowrap;

  text-overflow: ellipsis;
  overflow: hidden;

  font-weight: bold;
`

const State = styled.span<{$color: string}>`
  color: ${({$color}) => $color};
  font-size: 8px;

  width: 100%;
  text-align: center;
  white-space: nowrap;

  text-overflow: ellipsis;
  overflow: hidden;
`
